import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import Sidebar from "../common/sidebar";
import Agent from "./agent";
import Avendor from "./avendor";
import Vendor from "./vendor";
import axios from "axios";
import Navigation2 from "../navigation2";
function B2b(props) {
  var type = props.match.params.type;
  const [previlage, setPrevilage] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setPrevilage(res.data.previlage[0]?.previlage);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  return (
    <>
      <Navigation2 />

      <div className="col-lg-2">
        <Sidebar />
      </div>

      {type == "agent" &&
      previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
        <Agent />
      ) : type == "vendor" &&
        previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
        <Vendor />
      ) : type == "avendor" && previlage.includes("Airlines Vendor") ? (
        <Avendor />
      ) : null}
    </>
  );
}

export default withRouter(B2b);
