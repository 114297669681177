import { useState, useEffect } from "react";
import axios from "axios";
import Navigation2 from "../navigation2";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Addgroupticket() {
  let history = useHistory();

  const [ctype, setCtype] = useState("");
  const [show, setShow] = useState(false);
  const [aid, setAid] = useState("");
  const [agents, setAgents] = useState([]);
  const [idate, setIdate] = useState("");
  const [airplane, setAirplane] = useState([]);
  const [pnr, setPnr] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [tnote, setTnote] = useState("");
  const [cname, setCname] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [vendor, setVendor] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");
  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");

  const [airline, setAirline] = useState("");
  const [user, setUser] = useState([{ customer: 1 }]);

  const [tuser, setTuser] = useState([]);

  function clearFeild() {
    setCtype("");
    setAid("");
    setIdate("");
    setAirline("");
    setPnr("");
    setTnote("");
    setVendor("");
  }

  function handleSubmit(e) {
    e.preventDefault();

    var arrival = arr1 + " " + arr2 + " " + t1 + ":" + t2;
    var departure = de1 + " " + de2 + " " + d1 + ":" + d2;

    const data = new FormData();
    data.append("ctype", ctype);
    data.append("aid", aid);
    data.append("idate", idate);
    data.append("airline", airline);
    data.append("pnr", pnr);
    data.append("departure", departure);
    data.append("arrival", arrival);
    data.append("tnote", tnote);
    data.append("vendor", vendor);
    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/postgticket",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        clearFeild();

        toast.warn("Added !", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });

        history.push("/addgroupcus/" + res.data.message);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchalltypevendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navigation2 />

      <br></br>
      <div class="container">
        <div class="addVendor">
          <h3>Add Group Ticket</h3>
          <hr />
          <form onSubmit={handleSubmit}>
            <div class="form-group row py-1">
              <label for="" class="col-lg-2 col-12 col-form-label infotext">
                Customer Type
              </label>
              <div class="col-lg-4 mt-2">
                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="ctype"
                    id="fit"
                    type="radio"
                    class="form-check-input"
                    value="regular"
                    required=""
                    checked={ctype == "regular" ? true : false}
                    onChange={(e) => {
                      setCtype(e.target.value);
                      setShow(false);
                    }}
                  />
                  <label for="_0" class="form-check-label infotext mr-2">
                    Regular
                  </label>
                </div>

                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="ctype"
                    id="fit"
                    type="radio"
                    class="form-check-input"
                    value="agent"
                    required="required"
                    checked={ctype == "agent" ? true : false}
                    onChange={(e) => {
                      setCtype(e.target.value);
                      setShow(true);
                    }}
                  />
                  <label for="_0" class="form-check-label infotext mr-2">
                    From Agent
                  </label>
                </div>
              </div>
            </div>

            {ctype == "agent" ? (
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Agent Name
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    {ctype == "agent" ? (
                      <select
                        class="form-control"
                        value={aid}
                        onChange={(e) => setAid(e.target.value)}
                      >
                        <option>Choose Agent</option>
                        {agents.map((item) => (
                          <option value={item.id}>{item.aname}</option>
                        ))}
                      </select>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}

            <div class="form-group row py-2">
              <label for="" class="col-2 col-form-label infotext">
                Issue Date
              </label>
              <div class="col-10">
                <input
                  id=""
                  name=""
                  type="date"
                  class="form-control"
                  onChange={(e) => setIdate(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="text" class="col-2 col-form-label infotext">
                Airlines Name
              </label>
              <div class="col-10">
                <div class="input-group">
                  <select
                    id="inputState"
                    class="form-select form-control"
                    value={airline}
                    onChange={(e) => setAirline(e.target.value)}
                  >
                    <option class="form-control" selected>
                      Choose Airlines
                    </option>
                    <option>Air Arabia</option>
                    <option>Air Asia</option>
                    <option>Air India</option>
                    <option>Air KZB Company Ltd.</option>
                    <option>Biman Bangladesh Airlines</option>
                    <option>Bangkok Airlines</option>
                    <option>Bismillah Airlines</option>
                    <option>British Airways</option>
                    <option>Cathay Pacific</option>
                    <option>China Eastern Airlines</option>
                    <option>China Southern Airlines</option>
                    <option>Dragonair</option>
                    <option>Etihad Airways</option>
                    <option>Finnair</option>
                    <option>Fly Emirates</option>
                    <option>Fly Dubai</option>
                    <option>Gulf Air</option>
                    <option>Himalaya Airlines</option>
                    <option>Indigo</option>
                    <option>Jazeera Airways</option>
                    <option>Jet Airways</option>
                    <option>Kuwait Airways</option>
                    <option>Malaysia Airlines</option>
                    <option>Malindo Air</option>
                    <option>Novoair</option>
                    <option>Oman Air</option>
                    <option>Pakistan International Airlines</option>
                    <option>Qatar Airways</option>
                    <option>Regent Airways</option>
                    <option>Salam Air</option>
                    <option>Saudi Arabian Airlines</option>
                    <option>Saudia</option>
                    <option>Singapore Airlines</option>
                    <option>SpiceJet</option>
                    <option>Srilankan Airlines</option>
                    <option>Thai Airways</option>
                    <option>Thai Airways International</option>
                    <option>Turkish Airlines</option>
                    <option>United Airlines</option>
                    <option>US-Bangla Airlines</option>
                    <option>Yemenia</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="textarea" class="col-2 col-form-label infotext">
                PNR
              </label>
              <div class="col-10">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  onChange={(e) => setPnr(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-1">
              <label for="" class="col-lg-2 col-12 col-form-label infotext">
                Departure
              </label>
              <div class="col-lg-2 col-6 pr-1">
                <input
                  id=""
                  name=""
                  type="text"
                  placeholder="Ex. DAC"
                  class="form-control"
                  required=""
                  value={de1}
                  onChange={(e) => setDe1(e.target.value)}
                />
              </div>
              <div class="col-lg-2 col-6 pl-0">
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #ddd",
                    overflow: "auto",
                    padding: 5,
                    borderRadius: 4,
                  }}
                >
                  <input
                    id=""
                    name=""
                    type="date"
                    placeholder=""
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      fontSize: 14,
                      height: 26,
                    }}
                    //  class="form-control"
                    required=""
                    value={de2}
                    onChange={(e) => {
                      setDe2(e.target.value);
                      document.getElementById("clbtn2").click();
                    }}
                  />
                  <i
                    class="fa fa-clock-o"
                    id="clbtn2"
                    data-toggle="modal"
                    data-target="#exampleModal2"
                    style={{ margin: 2, fontSize: 20, display: "none" }}
                  ></i>
                </div>
              </div>

              <label
                for=""
                class="col-lg-2 col-12 marginTop col-form-label infotext"
              >
                Arrival
              </label>
              <div class="col-lg-2 col-6 pr-1">
                <input
                  id=""
                  name=""
                  type="text"
                  placeholder="Ex. CGP"
                  class="form-control"
                  required=""
                  value={arr1}
                  onChange={(e) => setArr1(e.target.value)}
                />
              </div>
              <div class="col-lg-2 col-6 pl-0">
                <div
                  style={{
                    display: "flex",
                    border: "1px solid #ddd",
                    overflow: "auto",
                    padding: 5,
                    borderRadius: 4,
                  }}
                >
                  <input
                    id=""
                    name=""
                    type="date"
                    placeholder=""
                    style={{
                      border: "none",
                      outline: "none",
                      width: "100%",
                      fontSize: 14,
                      height: 26,
                    }}
                    //  class="form-control"
                    required=""
                    value={arr2}
                    onChange={(e) => {
                      setArr2(e.target.value);
                      document.getElementById("clbtn").click();
                    }}
                  />
                  <i
                    class="fa fa-clock-o"
                    id="clbtn"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  ></i>
                </div>
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="textarea" class="col-2 col-form-label infotext">
                Transit Note
              </label>
              <div class="col-10">
                <input
                  id="textarea"
                  name="textarea"
                  cols="40"
                  rows="2"
                  class="form-control"
                  onChange={(e) => setTnote(e.target.value)}
                ></input>
              </div>
            </div>

            <div class="form-group row py-1">
              <label for="" class="col-lg-2 col-12 col-form-label infotext">
                Vendor Name
              </label>
              <div class="col-lg-4 col-12">
                <div class="input-group">
                  <select
                    id="inputState"
                    class="form-select form-control"
                    value={vendor}
                    onChange={(e) => setVendor(e.target.value)}
                  >
                    <option class="form-control" selected>
                      Choose Vendor
                    </option>

                    {vendors.map((item) => {
                      if (item.type == "ticket") {
                        return <option value={item.id}>{item.vname}</option>;
                      }
                    })}
                  </select>
                </div>
              </div>
            </div>

            {/* Bank info */}

            {/* Bank info */}

            {/* <div class="row mb-3">
              <div class="col-2"></div>
              <div class="col-10 text-center">
                
                  <button
                    class="btn btn-primary bg-light"
                    style={{ color: "black" }}
                    onClick={()=>{
                      var newItem= {customer : 2}
                      setUser(oldItem=> [...oldItem,newItem])
                    }}
                  >
                    Add More Customer
                  </button>
                
              </div>
            </div> */}

            {/* <div class="form-group row py-1">
                    <label
                      for=""
                      class="col-lg-2 col-4 col-form-label infotext"
                    >
                      Selling Price
                    </label>
                    <div class="col-lg-2 col-8 me-auto">
                      <input
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        required="required"
                        value={gross}
                        onChange={(e) => setGross(e.target.value)}
                      />
                    </div>

                    <label
                      for=""
                      class="col-lg-2 col-4 marginTop col-form-label infotext"
                    >
                      VAT & AIT
                    </label>
                    <div class="col-lg-2 col-8 marginTop me-auto">
                      <input
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        placeholder="Ex. 0.03"
                        required=""
                        value={vat}
                        onChange={(e) => {
                          var p =
                            Number(gross) -
                            (Number(base) + Number(e.target.value));
                          setVat(e.target.value);
                          setProfit(p);
                        }}
                      />
                    </div>
                    <label
                      for=""
                      class="col-lg-2 col-4 marginTop col-form-label infotext"
                    >
                      Profit
                    </label>
                    <div class="col-lg-2 col-8 marginTop me-auto">
                      <input
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        required=""
                        value={profit}
                        readOnly
                        onChange={(e) => setProfit(e.target.value)}
                      />
                    </div>
            </div> */}

            <div class="form-group row py-3 justify-content-end">
              <div class="col-10">
                <div class="d-grid gap-2 text-center">
                  <button class="btn btn-primary w-100 submitbtn">Next</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Addgroupticket;
