import React, { useEffect, useState } from "react";
import ticket from "../images/domTicket.png";
import intticket from "../images/intTicket.png";
import visa from "../images/visa.png";
import booking from "../images/hotel.png";
import vendor from "../images/vendor.png";
import accounts from "../images/accounts.png";
import settings from "../images/settings.png";
import pservice from "../images/package_service.png";
import gticket from "../images/gTicket.png";
import staf from "../images/staf.png";
import umrah from "../images/Umrah.png";

import hajj2 from "../images/Hajj.svg";

import medical2 from "../images/Medical.svg";
import airlinesv from "../images/airlinesv.png";

import manpower from "../images/ManPower.png";
import agent from "../images/Agent.png";
import passport from "../images/Passport.png";
import Navigation from "./navigation";
import { withRouter, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import axios from "axios";
import Footer from "./footer";

function Dashboard() {
  const [role, setRole] = useState(false);
  const [previlage, setPrevilage] = useState("");

  const [services, setServices] = useState([
    {
      service: "Package",
      srt: "Package",
      tag: "Services",
      to: "/customer/packages",
      url: "customer/packages",
      img: pservice,
    },
    {
      service: "Domestic",
      srt: "Domestic",
      tag: "Ticket",
      to: "/customer/domestic",
      url: "customer/domestic",
      img: ticket,
    },
    {
      service: "International",
      srt: "International",
      tag: "Ticket",
      to: "/customer/international",
      url: "customer/international",
      img: intticket,
    },
    {
      service: "Group",
      srt: "Group Ticket",
      tag: "Ticket",
      to: "/gticket",
      url: "gticket",
      img: gticket,
    },
    {
      service: "Issue",
      srt: "Visa",
      tag: "Visa",
      to: "/customer/visa",
      url: "customer/visa",
      img: visa,
    },
    {
      service: "Booking",
      srt: "Hotel",
      tag: "Hotel",
      to: "/customer/hotel",
      url: "customer/hotel",
      img: booking,
    },
    {
      service: "Umrah",
      srt: "Umrah",
      tag: "Processing",
      to: "/customer/umrah",
      url: "customer/umrah",
      img: umrah,
    },
    {
      service: "Hajj",
      srt: "Hajj",
      tag: "Processing",
      to: "/customer/hajj",
      url: "customer/hajj",
      img: hajj2,
    },
    {
      service: "Medical",
      srt: "Medical",
      tag: "Processing",
      to: "/customer/medical",
      url: "customer/medical",
      img: medical2,
    },
    {
      service: "Manpower",
      srt: "Manpower",
      tag: "Approval",
      to: "/customer/manpower",
      url: "customer/manpower",
      img: manpower,
    },
    {
      service: "Passport",
      srt: "Passport",
      tag: "Processing",
      to: "/customer/passport",
      url: "customer/passport",
      img: passport,
    },
    {
      service: "Reseller",
      srt: "Agent",
      tag: "Agent",
      to: "/b2b/agent",
      url: "b2b/agent",
      img: agent,
    },
    {
      service: "Vendor",
      srt: "Vendor",
      tag: "Agency",
      to: "/b2b/vendor",
      url: "b2b/vendor",
      img: vendor,
    },
    {
      service: "IATA/Airlines",
      srt: "Airlines Vendor",
      tag: "Vendor",
      to: "/b2b/avendor",
      url: "b2b/avendor",
      img: airlinesv,
    },
    {
      service: "Accounts",
      srt: "Accounts Management",
      tag: "Management",
      to: "/accounts",
      url: "accounts",
      img: accounts,
    },
    {
      service: "Staff",
      srt: "Staff Management",
      tag: "Management",
      to: "/staff",
      url: "staff",
      img: staf,
    },
  ]);

  useEffect(() => {
    axios
      .post("https://sr-api.flydocx.com/wiki/")
      .then((res) => {
        console.log("hey");
        console.log(res);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setPrevilage(res.data.previlage[0]?.previlage);
        if (res.data.message == "") {
          localStorage.removeItem("token");
        } else {
          setRole(res.data.message[0].role);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navigation />
      <div class="container-fluid mb-4">
        <div class="row">
          <div class="col-lg-9 col-12 dashmenu">
            <div class="row mt-lg-4 mt-3">
              {services.map((item) => (
                <div class="col-lg-3 col-6">
                  <Link
                    to={
                      previlage
                        .toLocaleLowerCase()
                        .includes(item.srt.toLocaleLowerCase())
                        ? item.to
                        : "#"
                    }
                    class="card mainicon text-center btn"
                  >
                    <div>
                      <img
                        src={item.img}
                        class="card-img-top grid_image mt-2 dash-left-bar-3"
                        alt="..."
                      />
                    </div>
                    <div class="m-1 text-center dash-left-bar-4">
                      <p class="card-title dash-left-bar-5">{item.service}</p>
                      <div class="card-text dash-left-bar-6">{item.tag}</div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <Sidebar />
        </div>
      </div>

      <Footer />
    </>
  );
}

export default withRouter(Dashboard);
