import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import formatDate from "../functions/timeformat";

function Purchaseticket({ name, uid, active, close }) {
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [fno, setFno] = useState("");
  const [tserial, setTserial] = useState("");
  const [quan, setQuan] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [price, setPrice] = useState("");
  const [purchasehis, setPurchasehis] = useState([]);
  const [airplane, setAirplane] = useState("");
  const [date, setDate] = useState("");

  function paypurchase() {
    var departure = de1 + " " + de2;
    var arrival = arr1 + " " + arr2;

    if (departure == "") {
      toast.warn("Departure is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (arrival == "") {
      toast.warn("Arrival is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (quan == "") {
      toast.warn("Quantity is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (price == "") {
      toast.warn("Price is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (tserial == "") {
      toast.warn("Ticket serial is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    const data = new FormData();
    data.append("airplane", airplane);
    data.append("fno", fno);
    data.append("departure", departure);
    data.append("arrival", arrival);
    data.append("quan", quan);
    data.append("price", price);
    data.append("tserial", tserial);
    data.append("date", date);
    data.append("uid", uid);

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/paypurchase2",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    setAirplane("");
    setDeparture("");
    setArrival("");
    setQuan("");
    setPrice("");
    setTserial("");

    sindata(uid);
    document.getElementById("sofrm").reset();
  }

  function sindata(x) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/sinpurchase",
        {
          id: x,
        }
      )
      .then((res) => setPurchasehis(res.data.message))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    sindata(uid);
  }, [uid]);

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active full" : "mp-Modal"}>
        <div
          class="tPurch_status"
          data-keyboard="false"
          data-backdrop="static"
          id="pTicketstatus"
          tabindex="1"
          role="dialog"
          aria-labelledby="pTicketModalLabel"
          aria-hidden="true"
        >
          <div class="" role="document">
            <div class="">
              <div class="mHeader">
                <i
                  onClick={() => {
                    close(false);
                  }}
                  className="fa fa-close cusIcon"
                ></i>

                <p class="modal-title pModalheadear" id="pTicketModalLabel">
                  Purchase Ticket
                </p>
              </div>

              <div class="row ml-3 my-3" style={{ fontSize: 18 }}>
                <div class="col-lg-3 col-4">
                  <p class="mb-2 font-weight-bold">Vendor Name</p>
                </div>
                <div class="col-lg-9 col-8">
                  <p class="mb-2 font-weight-bold">{name}</p>
                </div>
              </div>

              <div class="row mx-auto mb-4">
                <div class="pModalbody border px-0 col-lg-4 ">
                  <form id="sofrm">
                    <div class="">
                      <p class="font-weight-bold bg-light border-bottom px-2 py-2">
                        New Purchase
                      </p>

                      <div class="form-group row py-1 px-2">
                        <label
                          for=""
                          class="col-lg-3 col-4 col-form-label infotext"
                        >
                          Airplane
                        </label>
                        <div class="col-lg-9 col-12 input-group">
                          <div class="input-group-prepend"></div>
                          <select
                            id="inputState"
                            class="form-select form-control"
                            onChange={(e) => setAirplane(e.target.value)}
                          >
                            <option class="form-control" selected>
                              Choose Airlines
                            </option>
                            <option>Air Arabia</option>
                            <option>Air Asia</option>
                            <option>Air India</option>
                            <option>Air KZB Company Ltd.</option>
                            <option>Biman Bangladesh Airlines</option>
                            <option>Bangkok Airlines</option>
                            <option>Bismillah Airlines</option>
                            <option>British Airways</option>
                            <option>Cathay Pacific</option>
                            <option>China Eastern Airlines</option>
                            <option>China Southern Airlines</option>
                            <option>Dragonair</option>
                            <option>Etihad Airways</option>
                            <option>Finnair</option>
                            <option>Fly Emirates</option>
                            <option>Fly Dubai</option>
                            <option>Gulf Air</option>
                            <option>Himalaya Airlines</option>
                            <option>Indigo</option>
                            <option>Jazeera Airways</option>
                            <option>Jet Airways</option>
                            <option>Kuwait Airways</option>
                            <option>Malaysia Airlines</option>
                            <option>Malindo Air</option>
                            <option>Novoair</option>
                            <option>Oman Air</option>
                            <option>Pakistan International Airlines</option>
                            <option>Qatar Airways</option>
                            <option>Regent Airways</option>
                            <option>Salam Air</option>
                            <option>Saudi Arabian Airlines</option>
                            <option>Saudia</option>
                            <option>Singapore Airlines</option>
                            <option>SpiceJet</option>
                            <option>Srilankan Airlines</option>
                            <option>Thai Airways</option>
                            <option>Thai Airways International</option>
                            <option>Turkish Airlines</option>
                            <option>United Airlines</option>
                            <option>US-Bangla Airlines</option>
                            <option>Yemenia</option>
                          </select>
                        </div>
                      </div>

                      <div class="form-group row px-2">
                        <label
                          for=""
                          class="col-lg-3 col-4 col-form-label infotext"
                        >
                          Date
                        </label>
                        <div class="col-lg-9 col-12">
                          <input
                            id=""
                            name=""
                            type="date"
                            class="form-control"
                            required="required"
                            onChange={(e) => setDate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div class="form-group row px-2">
                        <label
                          for=""
                          class="col-lg-3 col-12 col-form-label infotext"
                        >
                          Departure
                        </label>
                        <div class="col-lg-4 col-6 pr-1">
                          <input
                            id=""
                            name=""
                            type="text"
                            placeholder="Ex. DAC"
                            class="form-control"
                            required="required"
                            onChange={(e) => setDe1(e.target.value)}
                          />
                        </div>
                        <div class="col-lg-5 col-6 pl-0">
                          <div
                            style={{
                              display: "flex",
                              border: "1px solid #ddd",
                              overflow: "auto",
                              padding: 5,
                              borderRadius: 4,
                            }}
                          >
                            <input
                              id=""
                              name=""
                              type="date"
                              placeholder="Ex. 14:45 24 April"
                              style={{
                                border: "none",
                                outline: "none",
                                height: 26,
                                width: "100%",
                                fontSize: 14,
                              }}
                              //  class="form-control"
                              required="required"
                              onChange={(e) => setDe2(e.target.value)}
                            />
                            <i
                              class="fa fa-clock-o"
                              id="clbtn2"
                              data-toggle="modal"
                              data-target="#exampleModal2"
                              style={{
                                margin: 2,
                                fontSize: 20,
                                display: "none",
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row px-2">
                        <label
                          for=""
                          class="col-lg-3 col-12 col-form-label infotext"
                        >
                          Arrival
                        </label>
                        <div class="col-lg-4 col-6 pr-1">
                          <input
                            id=""
                            name=""
                            type="text"
                            placeholder="Ex. DXB"
                            class="form-control"
                            required="required"
                            onChange={(e) => setArr1(e.target.value)}
                          />
                        </div>
                        <div class="col-lg-5 col-6 pl-0">
                          <div
                            style={{
                              display: "flex",
                              border: "1px solid #ddd",
                              overflow: "auto",
                              padding: 5,
                              borderRadius: 4,
                            }}
                          >
                            <input
                              id=""
                              name=""
                              type="date"
                              placeholder="Ex. 14:45 24 April"
                              style={{
                                border: "none",
                                outline: "none",
                                height: 26,
                                width: "100%",
                                fontSize: 14,
                              }}
                              //  class="form-control"
                              required="required"
                              onChange={(e) => setArr2(e.target.value)}
                            />
                            <i
                              class="fa fa-clock-o"
                              id="clbtn"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              style={{
                                margin: 2,
                                fontSize: 20,
                                display: "none",
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row px-2">
                        <label
                          for=""
                          class="col-lg-3 col-4 col-form-label infotext"
                        >
                          Flight No
                        </label>
                        <div class="col-lg-9 col-12">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => setFno(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="form-group row px-2">
                        <label
                          for=""
                          class="col-lg-3 col-4 col-form-label infotext"
                        >
                          SL. / PNR
                        </label>
                        <div class="col-lg-9 col-12">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => setTserial(e.target.value)}
                          />
                        </div>
                      </div>

                      <div class="form-group row px-2">
                        <label
                          for=""
                          class="col-lg-3 col-4 col-form-label infotext"
                        >
                          Quantity
                        </label>
                        <div class="col-lg-3 col-8">
                          <div class="input-group">
                            <input
                              id=""
                              name=""
                              type="text"
                              class="form-control"
                              required="required"
                              onChange={(e) => setQuan(e.target.value)}
                            />
                          </div>
                        </div>

                        <label
                          for=""
                          class="col-lg-2 col-4 marginTop col-form-label infotext"
                        >
                          Price
                        </label>
                        <div class="col-lg-4 marginTop col-8">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>

                  <p class="px-2" style={{ fontSize: 14, color: "#ff6800" }}>
                    *This data can not able to change or delete. Please check
                    again before submit.
                  </p>

                  <div class="px-2">
                    <button
                      type="button"
                      class="btn paymentUpdate w-100 mb-2 "
                      onClick={() => {
                        paypurchase();
                      }}
                    >
                      Add Purchase
                    </button>
                  </div>
                </div>

                <div
                  class="col-lg-7 border px-0"
                  style={{ height: 550, overflow: "auto" }}
                >
                  <p class="font-weight-bold border-bottom p-2 mb-0 bg-light">
                    Purchase List
                  </p>
                  <div class="ptl">
                    <table class="table" style={{ fontSize: 14 }}>
                      <thead class="bg-light text-center">
                        <tr>
                          <th scope="col" class="py-1">
                            Date
                          </th>
                          <th scope="col" class="py-1">
                            Airlines
                          </th>
                          <th scope="col" class="py-1">
                            Departure
                          </th>
                          <th scope="col" class="py-1">
                            Arrival
                          </th>
                          <th scope="col" class="py-1">
                            Quantity
                          </th>
                          <th scope="col" class="py-1">
                            SL/PNR
                          </th>
                          <th scope="col" class="py-1">
                            Fare
                          </th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        {purchasehis.map((item) => (
                          <tr>
                            <td>{formatDate(item.date)}</td>
                            <td>{item.airplane}</td>
                            <td>{item.departure}</td>
                            <td>{item.arrival}</td>
                            <td>{item.quantity}</td>
                            <td>{item.tserial}</td>
                            <td>{item.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchaseticket;
