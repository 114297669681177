import { useState, useEffect } from "react";
import axios from "axios";
function Pdfooter() {
  const [role, setRole] = useState(false);
  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].name);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  return (
    <div class="dflex" style={{ marginLeft: 50, marginRight: 50 }}>
      <p style={{ fontSize: 14, fontWeight: "bold" }}>
        Developed by mPair Technologies Ltd.
      </p>
      <p
        style={{
          fontSize: 14,
          fontWeight: "bold",
          position: "absolute",
          right: 0,
        }}
      >
        Generated by {role}
      </p>
    </div>
  );
}

export default Pdfooter;
