import { useState, useEffect } from "react";
import axios from "axios";
import Navigation2 from "../navigation2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { withRouter } from "react-router-dom";
import uploading from "../../images/uploading.gif";
import Footer from "../../screens/footer";

function Updatehajj(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [customer, setCustomer] = useState([]);

  const [pass, setPass] = useState("");
  const [visa, setVisa] = useState("");
  const [idate, setIdate] = useState("");
  const [vval, setVval] = useState("");
  const [fno, setFno] = useState("");
  const [serial, setSerial] = useState("");
  const [depa, setDepa] = useState("");
  const [arri, setArri] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [load, setLoad] = useState(false);

  const [airplane, setAirplane] = useState("");

  const [dloca, setDloca] = useState("");
  const [aloca, setAloca] = useState("");

  const [mno, setMno] = useState("");
  const [transno, setTransno] = useState("");

  const [sno, setSno] = useState("");
  const [mealno, setMealno] = useState("");

  const [gpnr, setGpnr] = useState("");

  const [edate, setEdate] = useState("");
  const [cof, setCof] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchshajj",
        {
          id: id,
        }
      )
      .then((res) => {
        setPass(res.data.message[0].passport);
        setHname(res.data.message[0].hname);
        setIdate(res.data.message[0].idate);
        setFno(res.data.message[0].fno);
        setVval(res.data.message[0].vval);
        setVisa(res.data.message[0].visa);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSerial(res.data.message[0].serial);
        setCin(res.data.message[0].cin);
        setCout(res.data.message[0].cout);
        setAirplane(res.data.message[0].airplane);

        setDloca(res.data.message[0].dloca);
        setAloca(res.data.message[0].aloca);

        setMno(res.data.message[0].mono);
        setTransno(res.data.message[0].transno);

        setSno(res.data.message[0].sno);

        setMealno(res.data.message[0].mealno);

        setGpnr(res.data.message[0].gpnr);

        setEdate(res.data.message[0].edate);

        setCof(res.data.message[0].cof);

        setArri(res.data.message[0].arrival);
        setDepa(res.data.message[0].departure);

        setNote(res.data.message[0].note);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  function handleSubmit() {
    var arrival = arri;
    var departure = depa;
    const data = new FormData();

    data.append("passport", pass);
    data.append("visa", visa);
    data.append("idate", idate);
    data.append("vval", vval);
    data.append("fno", fno);
    data.append("serial", serial);
    data.append("departure", departure);
    data.append("arrival", arrival);
    data.append("hname", hname);
    data.append("cin", cin);
    data.append("cout", cout);
    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("airplane", airplane);
    data.append("dloca", dloca);
    data.append("aloca", aloca);

    data.append("mno", mno);
    data.append("transno", transno);
    data.append("sno", sno);
    data.append("mealno", mealno);
    data.append("gpnr", gpnr);
    data.append("edate", edate);
    data.append("cof", cof);
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));
    data.append("id", id);

    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/uphajj",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          history.goBack();
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />

      <br></br>
      <div class="container">
        <div class="addCustomer">
          <div class="d-flex adcst">
            <h3 class="m-0">Update data</h3>
          </div>

          <hr />

          {load ? (
            <div class="bg-light upgifback">
              <center>
                <img src={uploading} class="upgif" alt=".." />
              </center>
            </div>
          ) : null}

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Visa
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={visa}
                onChange={(e) => setVisa(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Visa Issue Date
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={idate}
                onChange={(e) => setIdate(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Visa Validity
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={vval}
                onChange={(e) => setVval(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Airplane
            </label>
            <div class="col-lg-4 col-12">
              <select
                id="inputState"
                class="form-select form-control"
                value={airplane}
                onChange={(e) => setAirplane(e.target.value)}
              >
                <option class="form-control" selected>
                  Choose Airlines
                </option>
                <option>Air Arabia</option>
                <option>Air India</option>
                <option>Biman Bangladesh Airlines</option>
                <option>China Eastern Airlines</option>
                <option>China Southern Airlines</option>
                <option>Dragonair</option>
                <option>Etihad Airways</option>
                <option>Fly Emirates</option>
                <option>Fly Dubai</option>
                <option>Jet Airways</option>
                <option>Kuwait Airways</option>
                <option>Malaysia Airlines</option>
                <option>Novoair</option>
                <option>Pakistan International Airlines</option>
                <option>Qatar Airways</option>
                <option>Regent Airways</option>
                <option>Saudi Arabian Airlines</option>
                <option>Singapore Airlines</option>
                <option>SpiceJet</option>
                <option>Thai Airways</option>
                <option>Thai Airways International</option>
                <option>United Airlines</option>
                <option>US-Bangla Airlines</option>
                <option>Yemenia</option>
              </select>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Flight No
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={fno}
                onChange={(e) => setFno(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Serial / PNR
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
              ></input>
            </div>
            <label
              for="textarea"
              class="col-lg-2 col-4 infotext col-form-label"
            >
              GDS PNR
            </label>
            <div class="col-lg-4 col-8">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={gpnr}
                onChange={(e) => {
                  setGpnr(e.target.value);
                }}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label for="" class="col-lg-2 col-12 col-form-label infotext">
              Departure
            </label>
            <div class="col-lg-2 col-6 pr-1">
              <input
                id=""
                name=""
                type="text"
                placeholder="Ex. DHAKA"
                class="form-control"
                required=""
                value={dloca}
                onChange={(e) => setDloca(e.target.value)}
              />
            </div>
            <div class="col-lg-2 col-6 pl-0">
              <div
                style={{
                  display: "flex",
                  border: "1px solid #ddd",
                  overflow: "auto",
                  padding: 5,
                  borderRadius: 4,
                }}
              >
                <input
                  id=""
                  name=""
                  type="date"
                  placeholder=""
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: 14,
                    height: 26,
                  }}
                  //  class="form-control"
                  required=""
                  value={depa}
                  onChange={(e) => {
                    setDepa(e.target.value);
                    document.getElementById("clbtn2").click();
                  }}
                />
                <i
                  class="fa fa-clock-o"
                  id="clbtn2"
                  data-toggle="modal"
                  data-target="#exampleModal2"
                  style={{ margin: 2, fontSize: 20, display: "none" }}
                ></i>
              </div>
            </div>

            <label
              for=""
              class="col-lg-2 col-12 marginTop col-form-label infotext"
            >
              Arrival
            </label>
            <div class="col-lg-2 col-6 pr-1">
              <input
                id=""
                name=""
                type="text"
                placeholder="Ex. CHATTOGRAM"
                class="form-control"
                required=""
                value={aloca}
                onChange={(e) => setAloca(e.target.value)}
              />
            </div>
            <div class="col-lg-2 col-6 pl-0">
              <div
                style={{
                  display: "flex",
                  border: "1px solid #ddd",
                  overflow: "auto",
                  padding: 5,
                  borderRadius: 4,
                }}
              >
                <input
                  id=""
                  name=""
                  type="date"
                  placeholder=""
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: 14,
                    height: 26,
                  }}
                  //  class="form-control"
                  required=""
                  value={arri}
                  onChange={(e) => {
                    setArri(e.target.value);
                    document.getElementById("clbtn3").click();
                  }}
                />
                <i
                  class="fa fa-clock-o"
                  id="clbtn"
                  data-toggle="modal"
                  data-target="#exampleModal"
                ></i>
              </div>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Hotel name
            </label>
            <div class="col-lg-10 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={hname}
                onChange={(e) => setHname(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Check in
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={cin}
                onChange={(e) => setCin(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Check-out
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={cout}
                onChange={(e) => setCout(e.target.value)}
              ></input>
            </div>
          </div>
          <hr />

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-4 infotext col-form-label"
            >
              Mofa Amount
            </label>
            <div class="col-lg-4 col-8">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={mno}
                onChange={(e) => {
                  setMno(e.target.value);
                }}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-4 marginTop infotext col-form-label"
            >
              Transport Amount
            </label>
            <div class="col-lg-4 col-8 marginTop">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={transno}
                onChange={(e) => setTransno(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-4 infotext col-form-label"
            >
              Special Service
            </label>
            <div class="col-lg-4 col-8">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={sno}
                onChange={(e) => {
                  setSno(e.target.value);
                }}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-4 marginTop infotext col-form-label"
            >
              Meal Amount
            </label>
            <div class="col-lg-4 col-8 marginTop">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={mealno}
                onChange={(e) => setMealno(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label for="" class="col-lg-2 col-12 infotext col-form-label">
              Additional Note
            </label>
            <div class="col-lg-10 col-12">
              <textarea
                rows="4"
                cols="40"
                type="text"
                class="form-control"
                required=""
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
          </div>

          <hr />

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-4 infotext col-form-label"
            >
              Buying Price
            </label>
            <div class="col-lg-4 col-8">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={base}
                onChange={(e) => setBase(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-4 marginTop infotext col-form-label"
            >
              Selling Price
            </label>
            <div class="col-lg-4 col-8 marginTop">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={gross}
                onChange={(e) => setGross(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-4 infotext col-form-label"
            >
              VAT & AIT
            </label>
            <div class="col-lg-4 col-8">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={vat}
                onChange={(e) => {
                  var p =
                    Number(gross) - (Number(base) + Number(e.target.value));
                  setVat(e.target.value);
                  setProfit(p);
                }}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-4 marginTop infotext col-form-label"
            >
              Profit
            </label>
            <div class="col-lg-4 col-8 marginTop">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={profit}
                readOnly
                onChange={(e) => setProfit(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-2">
            <div class="col">
              <div class="d-grid gap-2 text-right">
                <button class="btn btn-primary w-100" onClick={handleSubmit}>
                  Update
                </button>
              </div>
            </div>
          </div>

          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default withRouter(Updatehajj);
