import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../common/loading";

function PackageService({
  active,
  uid,
  coun,
  setCoun,
  service,
  update,
  setUpdate,
  close,
}) {
  const [banks, setBanks] = useState([]);

  const [airplane, setAirplane] = useState("");
  const [pnr, setPnr] = useState("");
  const [tserial, setTserial] = useState("");
  const [arloca, setArloca] = useState("");
  const [ardate, setArdate] = useState("");
  const [vendor, setVendor] = useState("");
  const [country, setCountry] = useState("");
  const [vtype, setVtype] = useState("");
  const [sdate, setSdate] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [mcenter, setMcenter] = useState("");
  const [ddate, setDdate] = useState("");
  const [bmnumber, setBmnumber] = useState("");
  const [snumber, setSnumber] = useState("");
  const [enroll, setEnroll] = useState("");
  const [base, setBase] = useState("");
  const [load, setLoad] = useState(false);
  const [hide, setHide] = useState(false);
  const [packageId, setPackageId] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, [banks]);

  useEffect(() => {
    var data = new FormData();
    data.append("id", uid);
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/fetchServiceById",
        data
      )
      .then((res) => {
        if (res.data.message.length > 0) {
          console.log(res.data.message[0].base);

          setAirplane(res.data.message[0].airplane);
          setPnr(res.data.message[0].pnr);
          setTserial(res.data.message[0].tserial);
          setArdate(res.data.message[0].ardate);
          setArloca(res.data.message[0].arloca);
          setBase(res.data.message[0].base);

          setCountry(res.data.message[0].country);
          setVtype(res.data.message[0].vtype);
          setSdate(res.data.message[0].sdate);
          setVendor(res.data.message[0].vendor);
          setBase(res.data.message[0].base);

          setHname(res.data.message[0].hname);
          setCin(res.data.message[0].cin);

          setMcenter(res.data.message[0].mcenter);
          setDdate(res.data.message[0].ddate);
          setBmnumber(res.data.message[0].bmnumber);
          setDdate(res.data.message[0].ddate);
          setSnumber(res.data.message[0].snumber);
          setEnroll(res.data.message[0].enroll);

          setPackageId(res.data.message[0].packageId);
        }
      })
      .catch((err) => console.log(err));
  }, [uid]);

  function updateService() {
    setLoad(true);
    const data = new FormData();
    data.append("airplane", airplane);
    data.append("pnr", pnr);
    data.append("tserial", tserial);
    data.append("arloca", arloca);
    data.append("ardate", ardate);
    data.append("vendor", vendor);
    data.append("base", base);
    data.append("country", country);
    data.append("vtype", vtype);
    data.append("sdate", sdate);
    data.append("hname", hname);
    data.append("cin", cin);
    data.append("mcenter", mcenter);
    data.append("ddate", ddate);
    data.append("bmnumber", bmnumber);
    data.append("snumber", snumber);
    data.append("enroll", enroll);
    data.append("id", uid);
    data.append("packageId", packageId);
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/updateService",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        setUpdate(update + 1);
        setLoad(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <Loading load={load} />
        <div
          class="cpayment_status"
          data-keyboard="false"
          data-backdrop="static"
          id="expense2"
          tabindex="1"
          role="dialog"
          aria-labelledby="CpaymentModalLabel"
          aria-hidden="true"
        >
          <div class="" role="document">
            <div class="">
              <div class="mHeader">
                <i
                  onClick={() => {
                    close(false);
                  }}
                  className="fa fa-close cusIcon"
                ></i>

                <p class="modal-title pModalheadear" id="CpaymentModalLabel">
                  Package Service
                </p>
              </div>
              <form id="packageFrm" class="pModalbody py-4">
                {service.toLocaleLowerCase().includes("ticket") ? (
                  <>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        Airplane
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={airplane}
                          onChange={(e) => {
                            setAirplane(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        PNR
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={pnr}
                          onChange={(e) => {
                            setPnr(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Ticket Serial
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            id="text"
                            name="text"
                            type="text"
                            placeholder=""
                            class="form-control"
                            required=""
                            value={tserial}
                            onChange={(e) => {
                              setTserial(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="textarea"
                        class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                      >
                        Flight Sector
                      </label>
                      <div class="col-lg-6 col-2">
                        <input
                          type="text"
                          class="form-control"
                          required=""
                          placeholder="Ex. DAC/DXB"
                          value={arloca}
                          onChange={(e) => {
                            setArloca(e.target.value);
                          }}
                        ></input>
                      </div>
                      <div class="col-lg-4 col-3">
                        <input
                          type="date"
                          class="form-control"
                          required=""
                          value={ardate}
                          onChange={(e) => {
                            setArdate(e.target.value);
                          }}
                        ></input>
                      </div>
                    </div>
                    <div class="form-group row py-1">
                      <label
                        for="textarea"
                        class="col-lg-2 mt-lg-0 mt-4 col-12 infotext col-form-label"
                      >
                        Buying Price {base}
                      </label>
                      <div class="col-lg-4 col-12">
                        <input
                          type="text"
                          class="form-control"
                          required=""
                          value={base}
                          onChange={(e) => {
                            setBase(e.target.value);
                          }}
                        ></input>
                      </div>
                    </div>
                  </>
                ) : null}
                {service.toLocaleLowerCase().includes("visa") ? (
                  <>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        Country
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        Visa Type
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={vtype}
                          onChange={(e) => setVtype(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Stamping Date
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            id="text"
                            type="date"
                            class="form-control"
                            required=""
                            value={sdate}
                            onChange={(e) => setSdate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Buying Fare
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            required=""
                            value={base}
                            onChange={(e) => {
                              setBase(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {service.toLocaleLowerCase().includes("hotel") ? (
                  <>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        Country
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        Hotel Name
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={hname}
                          onChange={(e) => setHname(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Check-In
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            id="date"
                            name="text"
                            type="date"
                            class="form-control"
                            required=""
                            value={cin}
                            onChange={(e) => setCin(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Buying Fare
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            required=""
                            value={base}
                            onChange={(e) => setBase(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {service.toLocaleLowerCase().includes("manpower") ? (
                  <>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        Country
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        BMET Number
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={bmnumber}
                          onChange={(e) => setBmnumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Delivery Date
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            id="date"
                            name="text"
                            type="date"
                            class="form-control"
                            required=""
                            value={ddate}
                            onChange={(e) => setDdate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Buying Fare
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            required=""
                            value={base}
                            onChange={(e) => setBase(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {service.toLocaleLowerCase().includes("medical") ? (
                  <>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-12 col-form-label infotext"
                      >
                        Medical Center
                      </label>
                      <div class="col-lg-10 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={mcenter}
                          onChange={(e) => setMcenter(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Delivery Date
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            id="date"
                            name="text"
                            type="date"
                            class="form-control"
                            required=""
                            value={ddate}
                            onChange={(e) => setDdate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-2 col-12 infotext col-form-label"
                      >
                        Buying Fare
                      </label>
                      <div class="col-lg-10 col-12">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            required=""
                            value={base}
                            onChange={(e) => setBase(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                {service.toLocaleLowerCase().includes("passport") ? (
                  <>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-3 col-12 col-form-label infotext"
                      >
                        Slip Number
                      </label>
                      <div class="col-lg-9 col-12">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          value={snumber}
                          onChange={(e) => setSnumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-3 col-12 infotext col-form-label"
                      >
                        Enrollment Date
                      </label>
                      <div class="col-lg-9 col-12">
                        <div class="input-group">
                          <input
                            id="date"
                            name="text"
                            type="date"
                            class="form-control"
                            required=""
                            value={enroll}
                            onChange={(e) => setEnroll(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group row py-1">
                      <label
                        for="text"
                        class="col-lg-3 col-12 infotext col-form-label"
                      >
                        Buying Fare
                      </label>
                      <div class="col-lg-9 col-12">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            required=""
                            value={base}
                            onChange={(e) => setBase(e.target.value)}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </form>
              <div class="pModalbody">
                <button
                  type="button"
                  class="btn paymentUpdate w-100"
                  onClick={() => {
                    updateService();
                    close();
                    document.getElementById("packageFrm").reset();
                  }}
                >
                  Add Income
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PackageService;
