import { useState, useEffect } from "react";
import axios from "axios";
import Navigation2 from "../navigation2";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import uploading from "../../images/uploading.gif";
import Footer from "../../screens/footer";

function Packages(props) {
  var id = props.match.params.id;
  var type = props.match.params.type;

  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [fil, setFil] = useState("");
  const [cid, setCid] = useState(0);
  const [cserial, setCserial] = useState("");
  const [customer, setCustomer] = useState([]);

  const [pass, setPass] = useState("");
  const [visa, setVisa] = useState("");
  const [idate, setIdate] = useState("");
  const [vval, setVval] = useState("");
  const [fno, setFno] = useState("");
  const [serial, setSerial] = useState("");
  const [depa, setDepa] = useState("");
  const [arri, setArri] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [load, setLoad] = useState(false);
  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");
  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");
  const [show, setShow] = useState(false);

  const [ddate, setDdate] = useState("");
  const [edate, setEdate] = useState("");
  const [pedate, setPedate] = useState("");
  const [medical, setMedical] = useState("");
  const [cof, setCof] = useState("");
  const [note, setNote] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchsmedical",
        {
          id: id,
        }
      )
      .then((res) => {
        setPass(res.data.message[0].passport);
        setVisa(res.data.message[0].visa);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);

        setDdate(res.data.message[0].ddate);
        setEdate(res.data.message[0].edate);
        setIdate(res.data.message[0].idate);
        setPedate(res.data.message[0].pedate);
        setCof(res.data.message[0].cof);
        setNote(res.data.message[0].note);

        setMedical(res.data.message[0].medical);
        if (res.data.message[0].aid == 0) {
          setShow(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  function handleSubmit() {
    const data = new FormData();
    data.append("passport", pass);
    data.append("visa", visa);
    data.append("idate", idate);
    data.append("ddate", ddate);
    data.append("edate", edate);

    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);

    data.append("pedate", pedate);

    data.append("medical", medical);
    data.append("cof", cof);
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));
    data.append("id", id);

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upmedical",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          history.push("/medical");
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />

      <div
        class="modal fade hourmodal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>
            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    value={t1}
                    type="text"
                    placeholder="HH"
                    onChange={(e) => setT1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    value={t2}
                    type="text"
                    placeholder="MM"
                    onChange={(e) => setT2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button type="button" class="btn timeSelect" data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade hourmodal"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>

            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    value={d1}
                    placeholder="HH"
                    onChange={(e) => setD1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    value={d2}
                    placeholder="MM"
                    onChange={(e) => setD2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button type="button" class="btn timeSelect" data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <div class="container">
        <div class="addCustomer">
          <div class="d-flex adcst">
            <h3 class="m-0">Update data</h3>
          </div>

          <hr />

          {load ? (
            <div class="bg-light upgifback">
              <center>
                <img src={uploading} class="upgif" alt=".." />
              </center>
            </div>
          ) : null}

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Medical Center
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={medical}
                onChange={(e) => setMedical(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Visa No
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={visa}
                onChange={(e) => setVisa(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Issue Date
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={idate}
                onChange={(e) => setIdate(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Delivery Date
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={ddate}
                onChange={(e) => setDdate(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Expiry Date
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={edate}
                onChange={(e) => setEdate(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Care Of
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={cof}
                onChange={(e) => setCof(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label for="" class="col-lg-2 col-12 infotext col-form-label">
              Additional Note
            </label>
            <div class="col-lg-10 col-12">
              <textarea
                rows="4"
                cols="40"
                type="text"
                class="form-control"
                required=""
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
          </div>

          <hr />

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-4 infotext col-form-label"
            >
              Buying Price
            </label>
            <div class="col-lg-4 col-8">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={base}
                onChange={(e) => setBase(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-4 marginTop infotext col-form-label"
            >
              {show ? "Selling Price" : "Agent Fare"}
            </label>
            <div class="col-lg-4 col-8 marginTop">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={gross}
                onChange={(e) => setGross(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-4 infotext col-form-label"
            >
              VAT
            </label>
            <div class="col-lg-4 col-8">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                placeholder="0.03"
                required=""
                value={vat}
                onChange={(e) => {
                  var p =
                    Number(gross) - (Number(base) + Number(e.target.value));
                  setVat(e.target.value);
                  setProfit(p);
                }}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-4 marginTop infotext col-form-label"
            >
              Profit
            </label>
            <div class="col-lg-4 col-8 marginTop">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={profit}
                readOnly
              ></input>
            </div>
          </div>

          <div class="form-group row py-2">
            <div class="col">
              <div class="d-grid gap-2 text-right">
                <button class="btn btn-primary w-100" onClick={handleSubmit}>
                  Update
                </button>
              </div>
            </div>
          </div>

          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default withRouter(Packages);
