import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import axios from "axios";
import Printhead from "../common/printhead";

function Transaction() {
  const [cin, setCin] = useState([]);
  const [cout, setCout] = useState([]);
  const [expense, setExpense] = useState([]);
  const [refund, setRefund] = useState([]);

  const [bin, setBin] = useState([]);
  const [bout, setBout] = useState([]);
  const [bexpense, setBexpense] = useState([]);
  const [brefund, setBrefund] = useState([]);

  const [role, setRole] = useState(false);

  const [dat, setDat] = useState("");
  const [ptotal, setPtotal] = useState("");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [alldata, setAlldata] = useState([]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].name);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    var d = new Date();
    var m = d.getMonth() + 1;
    var day = d.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    if (m < 10) {
      m = "0" + m;
    }
    var x = d.getFullYear() + "-" + m + "-" + day;

    setDat(x);

    var y = "";
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchallcash",
        {
          date1: x,
          date2: y,
        }
      )
      .then((res) => {
        setCin(res.data.cin);
        setCout(res.data.cout);
        setExpense(res.data.expense);
        setRefund(res.data.refund);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchdallbank",
        {
          date1: x,
          date2: y,
        }
      )
      .then((res) => {
        setBin(res.data.bin);
        setBout(res.data.bout);
        setBrefund(res.data.refund);
        setBexpense(res.data.expense);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprevtotal",
        {
          date1: x,
        }
      )
      .then((res) => {
        var x =
          Number(res.data.cin) +
          Number(res.data.suspense[0].sin) -
          (Number(res.data.cout) +
            Number(res.data.refund) +
            Number(res.data.expense) +
            Number(res.data.suspense[0].sout));
        setPtotal(x);
      })
      .catch((err) => console.log(err));

    var y = "";
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchfullTransaction",
        {
          date1: x,
          date2: y,
        }
      )
      .then((res) => {
        setAlldata((item) => [
          ...item,
          ...res.data.cin,
          ...res.data.cout,
          ...res.data.expense,
          ...res.data.refund,
        ]);
      })
      .catch((err) => console.log(err));
  }, []);

  function dicash() {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchallcash",
        {
          date1: from,
          date2: to,
        }
      )
      .then((res) => {
        setCin(res.data.cin);
        setCout(res.data.cout);
        setExpense(res.data.expense);
        setRefund(res.data.refund);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchdallbank",
        {
          date1: from,
          date2: to,
        }
      )
      .then((res) => {
        setBin(res.data.bin);
        setBout(res.data.bout);
        setBrefund(res.data.refund);
        setBexpense(res.data.expense);
      })
      .catch((err) => console.log(err));

    setAlldata([]);
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchfullTransaction",
        {
          date1: from,
          date2: to,
        }
      )
      .then((res) => {
        setAlldata((item) => [
          ...item,
          ...res.data.cin,
          ...res.data.cout,
          ...res.data.expense,
          ...res.data.refund,
        ]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprevtotal",
        {
          date: from,
        }
      )
      .then((res) => {
        var x =
          Number(res.data.cin) +
          Number(res.data.suspense[0].sin) -
          (Number(res.data.cout) +
            Number(res.data.refund) +
            Number(res.data.expense) +
            Number(res.data.suspense[0].sout));
        setPtotal(x);
      })
      .catch((err) => console.log(err));
  }

  function getTo() {
    var i = 0;

    cin.map((item) => {
      i = i + item.amount;
    });
    return i;
  }

  function getTo2() {
    var i = 0;

    cout.map((item) => {
      i = i + item.amount;
    });

    expense.map((item) => {
      i = i + item.amount;
    });

    refund.map((item) => {
      i = i + item.amount;
    });

    return i;
  }

  function getTo3() {
    var i = 0;

    bin.map((item) => {
      i = i + item.amount;
    });
    return i;
  }

  function getTo4() {
    var i = 0;

    bout.map((item) => {
      i = i + item.amount;
    });

    bexpense.map((item) => {
      i = i + item.amount;
    });

    brefund.map((item) => {
      i = i + item.amount;
    });

    return i;
  }

  function printme() {
    window.print();
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <>
      <Navigation2 />

      <Printhead />

      <table style={{ width: "100%" }}>
        <thead style={{ backgroundColor: "white" }}>
          <tr>
            <td class="tranreportheader"></td>
          </tr>
          <br />
        </thead>

        <tbody>
          <tr>
            <td>
              <div class="container">
                <div class="dtran mt-0 pt-0" id="dtran">
                  <div class="">
                    <h4 class="m-0 text-center">Total Transaction</h4>
                  </div>
                  <div class="onprint text-center">
                    <p>
                      {from != "" && to != ""
                        ? formatDate(from) + "-" + formatDate(to)
                        : formatDate(dat)}
                    </p>
                  </div>

                  <div class="row align-items-center noprint justify-content-center bg-light border py-2 mx-auto">
                    <div class="col-lg-7 col-9 p-0 row align-items-center">
                      <div class="col-lg-4 col-9">
                        <input
                          type="date"
                          class="filtBTN m-0 mx-2 px-2"
                          onChange={(e) => setFrom(e.target.value)}
                        />
                      </div>
                      <div class="col-lg-1 font-weight-bold text-center">-</div>
                      <div class="col-lg-4 col-9">
                        <input
                          type="date"
                          class="filtBTN m-0 px-2"
                          onChange={(e) => setTo(e.target.value)}
                        />
                      </div>

                      <div class="col-lg-3 col-9 px-4">
                        <button
                          class="btn btn-warning noprint"
                          onClick={() => dicash()}
                          style={{ color: "white", height: 35, paddingTop: 4 }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>

                    <div class="col-lg-2 col-3">
                      <button
                        class="btn btn-warning noprint"
                        onClick={printme}
                        style={{ color: "white", height: 35, paddingTop: 4 }}
                      >
                        Download
                      </button>
                    </div>
                  </div>

                  <div class="prbm">
                    <div class="container mx-0 px-0">
                      <table
                        class="table stranwidth table-bordered"
                        id="table-to-xls5"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th width="10%" scope="col" class="p-1 px-2">
                              Date
                            </th>
                            <th width="40%" scope="col" class="p-1 px-2">
                              Narration
                            </th>
                            <th
                              width="20%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              Debit
                            </th>
                            <th
                              width="20%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              Credit
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {alldata
                            .sort((a, b) => {
                              var c = new Date(a.date);
                              var d = new Date(b.date);
                              return c - d;
                            })
                            .map((item) => (
                              <tr>
                                <td width="10%" scope="row" class="p-1 px-2">
                                  {formatDate(item.date)}
                                </td>
                                <td width="20%" class="p-1 px-2">
                                  {item.stype != "" ? (
                                    <>
                                      <i>Type -</i>
                                      {item.service == "expense"
                                        ? item.service + "" + item.description
                                        : item.stype}{" "}
                                      ,{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {item.name != "" && item.salary != 1 ? (
                                    <>
                                      <i>P/T -</i>
                                      {item.service == "expense"
                                        ? item.type
                                        : item.name}{" "}
                                      ,{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {item.type == "bank" ? (
                                    <>
                                      <i>Bank -</i> {item.bank}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {item.method == "bank" ? (
                                    <>
                                      <i>Bank -</i> {item.bank}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {item.salary == 1 ? (
                                    <>
                                      <i>Description -</i> {item.description}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {item.service == "refund" ? (
                                    <>( {item.service} )</>
                                  ) : null}
                                </td>
                                <td width="20%" class="text-right p-1 px-2">
                                  {item.service == "payment"
                                    ? item.stype == "vendor"
                                      ? item.amount
                                      : null
                                    : null}
                                  {item.service == "refund"
                                    ? item.stype != "vendor"
                                      ? item.amount
                                      : null
                                    : null}
                                  {item.service == "expense"
                                    ? item.amount
                                    : null}
                                </td>
                                <td width="20%" class="text-right p-1 px-2">
                                  {item.service == "payment"
                                    ? item.stype != "vendor"
                                      ? item.amount
                                      : null
                                    : null}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>

                      <br />

                      <h4>Total Balance</h4>

                      <table
                        class="table stranwidth table-bordered"
                        id="table-to-xls5"
                      >
                        <thead class="thead-light">
                          <tr>
                            <th width="40%" scope="col" class="p-1 px-2">
                              Type
                            </th>
                            <th
                              width="20%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              Debit
                            </th>
                            <th
                              width="20%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              Credit
                            </th>
                            <th
                              width="20%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              Balance
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th width="40%" scope="row" class="p-1 px-2">
                              Opening balance
                            </th>
                            <td width="20%" class="text-right p-1 px-2">
                              0
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              0
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              {ptotal}
                            </td>
                          </tr>

                          <tr>
                            <th width="40%" scope="row" class="p-1 px-2">
                              Cash In
                            </th>
                            <td width="20%" class="text-right p-1 px-2">
                              {getTo()}
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              0
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              {getTo() + ptotal}
                            </td>
                          </tr>

                          <tr>
                            <th width="40%" scope="row" class="p-1 px-2">
                              Cash Out
                            </th>
                            <td width="20%" class="text-right p-1 px-2">
                              0
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              {getTo2()}
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              {getTo() + ptotal - getTo2()}
                            </td>
                          </tr>

                          <tr>
                            <th width="40%" scope="row" class="p-1 px-2">
                              Bank In
                            </th>
                            <td width="20%" class="text-right p-1 px-2">
                              {getTo3()}
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              0
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              {getTo() + ptotal - getTo2() + getTo3()}
                            </td>
                          </tr>

                          <tr>
                            <th width="40%" scope="row" class="p-1 px-2">
                              Bank Out
                            </th>
                            <td width="20%" class="text-right p-1 px-2">
                              0
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              {getTo4()}
                            </td>
                            <td width="20%" class="text-right p-1 px-2">
                              {getTo() +
                                ptotal -
                                getTo2() +
                                getTo3() -
                                getTo4()}
                            </td>
                          </tr>

                          <tr>
                            <th width="40%" scope="row">
                              Total Balance
                            </th>
                            <th width="20%" scope="row" class="text-right"></th>
                            <th width="20%" scope="row" class="text-right"></th>
                            <td width="20%" class="text-right px-2">
                              {getTo() +
                                ptotal -
                                getTo2() +
                                getTo3() -
                                getTo4()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td style={{ height: "100px" }}></td>
          </tr>
        </tfoot>
      </table>

      <div class="footer" style={{ position: "fixed", bottom: 0, left: 0 }}>
        <div class="d-flex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p class="border-top border-dark border-t">
            <span class="">Accounts</span>
          </p>

          <p
            class="border-top  border-dark border-t"
            style={{ marginLeft: 250, marginRight: 250 }}
          >
            <span class="">Manager</span>
          </p>

          <p class="border-top border-dark border-t">
            <span class="">Proprietor</span>
          </p>
        </div>
        <div class="dflex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>
            Developed by mPair Technologies Ltd.
          </p>
          <p
            style={{
              fontSize: 14,
              fontWeight: "bold",
              position: "absolute",
              right: 0,
            }}
          >
            Generated by {role}
          </p>
        </div>
      </div>
    </>
  );
}

export default Transaction;
