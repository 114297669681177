import loading from "../../images/loading.gif"
function Loading({load}){
    return (
        <>
          {load ? <div class="cusloader">
             <img src={loading} class="loadinggif"/>
          </div> : null}
        </>
    )
}

export default Loading;