import {
    BrowserRouter as Router,
    Route,  
    Link
  } from "react-router-dom";  
import Hajjprofile from "./hajjprofile";
import Hotel from "./hotel";
import Manpower from "./manpower";
import Medicalprofile from "./medicalprofile";
import Passport from "./passport";
import Sgticket from "./sgticket";
import Ticket from "./ticket";
import Umrahprofile from "./Umrahprofile";
import Visa from "./visa";
import Packages from "./packages";
function Download(){
  return(
    <>
        <Route path="/hajjprofile/:id">
            <Hajjprofile/>
         </Route>
         <Route path="/hotel/:id">
            <Hotel/>
        </Route>
        <Route path="/manpower/:id">
            <Manpower/>
        </Route>
        <Route path="/medicalprofile/:id">
            <Medicalprofile/> 
        </Route>
        <Route path="/passport/:id">
            <Passport/>
        </Route>
        <Route path="/sgticket/:id">
            <Sgticket/>
        </Route>
        <Route path="/ticket/:id">
            <Ticket/>
        </Route>
        <Route path="/umrahprofile/:id">
            <Umrahprofile/>
        </Route>
        <Route path="/visa/:id">
            <Visa/>
        </Route>
        <Route path="/packages/:id">
            <Packages/>
        </Route>
    </>
  )
}
export default Download;