import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../..//screens/footer";
import Resizer from "react-image-file-resizer";

function Adcustomer() {
  let history = useHistory();

  const [customer, setCustomer] = useState([]);
  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [pval, setPval] = useState("");
  const [cserial, setCserial] = useState("");
  const [userData, setUsereData] = useState("");
  const [cid, setCid] = useState("");
  const [dob, setDob] = useState("");
  const [fil, setFil] = useState("");
  const [coun, setCoun] = useState(0);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        900,
        900,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const userPhoto = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);
    setUsereData(newFile);
  };

  function handleSubmit(e) {
    e.preventDefault();

    if (cid != "") {
      history.push("/adservice/" + cid);
    } else {
      const data = new FormData();
      data.append("cname", cname);
      data.append("cnum", cnum);
      data.append("cadd", cadd);
      data.append("dob", dob);

      data.append("passport", passport);

      data.append("cserial", cserial);
      data.append("nid", nid);
      data.append("pval", pval);
      data.append("user", userData);

      axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/adnewuser",
          data
        )
        .then((res) => {
          history.push("/adservice/" + res.data.userid);
        })
        .catch((err) => console.log(err));
    }
  }

  const clearField = () => {
    setCserial("");
    setCname("");
    setCadd("");
    setCnum("");
    setCid("");
    setPassport("");
  };

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => {
        setCustomer(res.data.message);

        if (res.data.lastid == "") {
          setCserial(1);
        } else {
          setCserial(Number(res.data.lastid) + 1);
        }
      })
      .catch((err) => console.log(err));
  }, [coun]);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <div class="addCustomer mb-5 pb-3">
          <div class="d-flex adcst col-lg-12 col-12">
            <h3 class="m-0">Add Customer</h3>

            <button
              class="old-customer ml-4 font-weight-bold"
              data-toggle="modal"
              data-target="#olduser"
            >
              + Old Customer
            </button>

            {cid == "" ? null : (
              <button
                class="old-customer ml-4 font-weight-bold"
                onClick={() => {
                  clearField();
                  setCoun(coun + 1);
                  document.getElementById("customer-form").reset();
                }}
              >
                Clear
              </button>
            )}
          </div>

          <div
            class="modal fade oldcs"
            id="olduser"
            tabindex="-1"
            data-backdrop="static"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog oldCustDialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Select Old Customer
                  </h5>
                  <button
                    id="hideit"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <input
                    class="ocsb"
                    type="text"
                    placeholder="Search customer"
                    onChange={(e) => setFil(e.target.value)}
                  />
                  {customer
                    .filter((item) => {
                      if (
                        item.cname.toLowerCase().includes(fil.toLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item) => (
                      <div
                        class="soc my-2 p-2"
                        onClick={() => {
                          setCname(item.cname);
                          setCnum(item.cnum);
                          setCadd(item.cadd);
                          setCid(item.id);
                          setCserial(item.id);
                          setDob(item.dob);
                          setNid(item.nid);
                          setPassport(item.passport);
                          setPval(item.pval);
                          document.getElementById("hideit").click();
                        }}
                      >
                        <p class="m-0">FD#{item.id}</p>
                        <p class="font-weight-bold m-0">{item.cname}</p>
                        <p class=" m-0">{item.cnum}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <hr />

          <form onSubmit={handleSubmit} id="customer-form">
            <div class="form-group row py-1">
              <label
                for="textarea"
                class="col-lg-3 col-12 infotext col-form-label"
              >
                Customer Serial
              </label>
              <div class="col-lg-9 col-12">
                <input
                  id="textarea"
                  name="text"
                  type="text"
                  class="form-control"
                  required=""
                  value={cserial}
                  onChange={(e) => setCserial(e.target.value)}
                  disabled={cid != "" ? true : false}
                  readOnly
                ></input>
              </div>
            </div>

            <div class="form-group row py-1">
              <label for="" class="col-lg-3 col-12 col-form-label infotext">
                Customer Name
              </label>
              <div class="col-lg-9 col-12">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                  value={cname}
                  onChange={(e) => setCname(e.target.value)}
                  disabled={cid != "" ? true : false}
                />
              </div>
            </div>

            <div class="form-group row py-1">
              <label for="text" class="col-lg-3 col-12 infotext col-form-label">
                Customer Mobile No
              </label>
              <div class="col-lg-4 col-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">+88</div>
                  </div>
                  <input
                    id="text"
                    name="text"
                    type="text"
                    placeholder="018XXXXXXXX"
                    class="form-control"
                    required=""
                    value={cnum}
                    onChange={(e) => setCnum(e.target.value)}
                    disabled={cid != "" ? true : false}
                  />
                </div>
              </div>

              <label
                for="textarea"
                class="col-lg-2 mt-lg-0 mt-4 col-12 infotext col-form-label"
              >
                Date of Birth
              </label>
              <div class="col-lg-3 col-12">
                <input
                  type="date"
                  class="form-control"
                  required=""
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  disabled={cid != "" ? true : false}
                ></input>
              </div>
            </div>

            <div class="form-group row py-1">
              <label for="text" class="col-lg-3 col-12 infotext col-form-label">
                Passport No
              </label>
              <div class="col-lg-4 col-12">
                <div class="input-group">
                  <input
                    id="text"
                    name="text"
                    type="text"
                    placeholder="XXXXXXXXXX"
                    class="form-control"
                    required=""
                    value={passport}
                    onChange={(e) => setPassport(e.target.value)}
                    disabled={cid != "" ? true : false}
                  />
                </div>
              </div>

              <label
                for="textarea"
                class="col-lg-2 mt-lg-0 mt-4 col-12 infotext col-form-label"
              >
                Passport Validity
              </label>
              <div class="col-lg-3 col-12">
                <input
                  type="date"
                  class="form-control"
                  required=""
                  value={pval}
                  onChange={(e) => setPval(e.target.value)}
                  disabled={cid != "" ? true : false}
                ></input>
              </div>
            </div>

            <div class="form-group row py-1">
              <label
                for="textarea"
                class="col-lg-3 col-12 infotext col-form-label"
              >
                Customer Address
              </label>
              <div class="col-lg-9 col-12">
                <textarea
                  id="textarea"
                  name="textarea"
                  cols="40"
                  rows="2"
                  class="form-control"
                  required=""
                  value={cadd}
                  onChange={(e) => setCadd(e.target.value)}
                  disabled={cid != "" ? true : false}
                ></textarea>
              </div>
            </div>

            <div class="form-group row py-1">
              <label
                for="textarea"
                class="col-lg-3 col-12 infotext col-form-label"
              >
                Customer photo
              </label>
              <div class="col-lg-4 col-12 me-auto">
                <input
                  type="file"
                  class="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  onChange={userPhoto}
                  disabled={cid != "" ? true : false}
                />
              </div>

              <label
                for="textarea"
                class="col-lg-1 col-12 infotext col-form-label"
              >
                NID
              </label>
              <div class="col-lg-4 col-12 me-auto">
                <input
                  type="text"
                  class="form-control"
                  value={nid}
                  onChange={(e) => setNid(e.target.value)}
                  disabled={cid != "" ? true : false}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <div class="col-lg-3"></div>
              <div class="col-lg-9 col-12">
                <button class="w-100 btn btn-primary">NEXT</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default withRouter(Adcustomer);
