import React, { useEffect } from "react";
import Staff from "./screens/staff";
import './App.css';
import './mPair.css';
import {
  BrowserRouter as Router,
  Route,  
  Link
} from "react-router-dom";  
import Dashboard from './screens/dashboard';   
import Customer from './screens/services/customer';
import Adcustomer from './screens/addService/adcustomer';
import Accounts from './screens/accounts';
import Reissue from './screens/reissue';
import Login from "./screens/login";
import Profile from "./screens/profile";
import Allcustomer from "./screens/allcustomer";
import Adservice from "./screens/addService/adservice";
import Gticket from "./screens/services/groupTicket";
import Reissuegt from "./screens/reissuegt";


import B2b from "./screens/b2b/b2b";



//Update

import Uroute from "./screens/updateservice/uroute";
import Addcomponent from "./screens/addComponent/addComponent";
import Report from "./screens/report/report";



//Download

import Download from "./screens/download/download";



 
function App() {  


  
  return (   
    <Router>    
      


      <Uroute/>
      <Addcomponent/>
      <Report/>
      <Download/>

      <Route exact path="/">  
        <Login/> 
      </Route> 
      
      <Route path="/dashboard">  
        <Dashboard/> 
      </Route>
    
      <Route path="/adcustomer">
        <Adcustomer/> 
      </Route>

      <Route path="/customer/:type">
        <Customer/> 
      </Route>


      <Route path="/b2b/:type">
        <B2b/> 
      </Route>


    


      <Route path="/staff">
        <Staff/>
      </Route>



      <Route path="/adservice/:cid">
        <Adservice/>
      </Route>



      <Route path="/accounts">
        <Accounts/>
      </Route>

      

      <Route path="/reissue/:id">
        <Reissue/>
      </Route>




      <Route path="/reissuegt/:id">
        <Reissuegt/>
      </Route>



      <Route path="/profile">
        <Profile/>
      </Route>


      <Route path="/gticket">
        <Gticket/>
      </Route>


      <Route path="/allcustomer">
        <Allcustomer/>
      </Route>
     
    </Router>    
  ); 
}

export default App;
