import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import client from "../../images/placeholder.jpg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Printhead from "../printhead";

function Medicalprofile(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [cserial, setCserial] = useState("");

  const [pass, setPass] = useState("");
  const [visa, setVisa] = useState("");
  const [idate, setIdate] = useState("");
  const [vval, setVval] = useState("");
  const [fno, setFno] = useState("");
  const [serial, setSerial] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [vendor, setVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [cphoto, setCphoto] = useState("");

  const [mno, setMno] = useState("");
  const [trans, setTrans] = useState("");
  const [sno, setSno] = useState("");
  const [mealno, setMealno] = useState("");
  const [ddate, setDdate] = useState("");
  const [edate, setEdate] = useState("");
  const [note, setNote] = useState("");

  const [pedate, setPedate] = useState("");
  const [medical, setMedical] = useState("");

  const [aid, setAid] = useState("");
  const [cof, setCof] = useState("");
  const [agents, setAgents] = useState([]);
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  function getStaff(x) {
    var i = "";
    staff.map((item) => {
      if (item.id == x) {
        i = item.sname;
      }
    });

    return i;
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchsmedical",
        {
          id: id,
        }
      )
      .then((res) => {
        setCserial(res.data.message[0].cserial);
        setCname(res.data.message[0].cname);
        setCnum(res.data.message[0].cnum);
        setCadd(res.data.message[0].cadd);

        setPass(res.data.message[0].passport);
        setVendor(res.data.message[0].vendor);

        setIdate(res.data.message[0].idate);
        setDdate(res.data.message[0].ddate);
        setEdate(res.data.message[0].edate);
        setAid(res.data.message[0].aid);
        setNote(res.data.message[0].note);

        setPedate(res.data.message[0].pedate);
        setMedical(res.data.message[0].medical);
        setCof(res.data.message[0].cof);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => setStaff(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1000);
  }, []);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <Printhead />

        <div class="prheadc mt-3">
          <h3>Service Name: Umrah</h3>
        </div>

        <div class="dtran cview">
          <div class="d-flex adcst">
            <h5 class="m-0">Customer Data</h5>
          </div>
          <hr />

          <div class="contentC">
            <div class="form-group vcinfo">
              <div class="col-3">
                {cphoto == "" ? (
                  <img src={client} class="clientpp2" />
                ) : (
                  <img
                    src={
                      "https://sr-api.flydocx.com/" +
                      process.env.REACT_APP_URL +
                      "/images/" +
                      cphoto
                    }
                    class="clientpp2"
                    alt="..."
                  />
                )}
              </div>
              <div class="col-9">
                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Serial
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cserial}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Name
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cname}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Mobile Number
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cnum}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Address
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cadd}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport No
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {pass}</p>
                  </div>
                </div>
                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport Validity
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {pedate}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Visa No
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {visa}</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="d-flex adcst">
                <h5 class="m-0">Service Data</h5>
              </div>
              <hr />

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Medical Center
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {medical}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Issue Date
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {idate}</p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Delivery date
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {ddate}</p>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Expiry Date
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {edate}</p>
                </div>
              </div>

              <hr />
              {aid == 0 ? null : (
                <div class="form-group row">
                  <label for="" class="col-2 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-10">
                    <p class="font-weight-bold m-0 p-1">: {getagent(aid)}</p>
                  </div>
                </div>
              )}
              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Vendor Name
                </label>
                <div class="col-4">
                  {vendors.map((y) => (
                    <p class="font-weight-bold m-0 pad1">
                      {y.id == vendor ? ": " + y.vname : null}
                    </p>
                  ))}
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Additional Note
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {note}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Care Of
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {getStaff(cof)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
          <div class="dflex" style={{ marginLeft: 10, marginRight: 10 }}>
            <p style={{ fontSize: 14, fontWeight: "bold" }}>
              Developed by mPair Technologies Ltd.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default withRouter(Medicalprofile);
