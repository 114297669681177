import { useState } from "react";
import axios from "axios";

var activeitem = [];
function Previlagemodal({ active, close, id }) {
  const [services, setServices] = useState([
    "Domestic",
    "International",
    "Visa",
    "Hotel",
    "Umrah",
    "Hajj",
    "Medical",
    "Passport",
    "Manpower",
    "Group Ticket",
    "Agent",
    "Vendor",
    "Airlines Vendor",
    "Accounts Management",
    "Staff Management",
    "Agency Setting",
  ]);

  function setPrevilage() {
    const data = new FormData();
    data.append("previlage", activeitem);
    data.append("uid", id);

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/setPrevilage",
        data
      )
      .then((res) => {
        document.getElementById("userFeild").reset();
        close(false);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <div className="d-flex">
          <i
            onClick={() => {
              close(false);
            }}
            className="fa fa-close cusIcon"
          ></i>
          <h5 className="m-2">Set Previlage</h5>
        </div>

        <div className="conatiner">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  <input
                    type="checkbox"
                    onChange={() => {
                      activeitem = [
                        "Domestic",
                        "International",
                        "Visa",
                        "Hotel",
                        "Umrah",
                        "Hajj",
                        "Medical",
                        "Passport",
                        "Manpower",
                        "Group Ticket",
                        "Agent",
                        "Vendor",
                        "Airlines Vendor",
                        "Accounts Management",
                        "Staff Management",
                        "Agency Setting",
                      ];
                      console.log(activeitem.length);
                    }}
                    style={{ marginRight: 8 }}
                    checked={activeitem.length == 16 ? true : false}
                  />
                  Select All
                </th>
              </tr>
            </thead>
            <tbody>
              {services.map((item) => (
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (activeitem.indexOf(item) == -1) {
                          activeitem.push(item);
                        } else {
                          var index = activeitem.indexOf(item);
                          activeitem.splice(index, 1);
                        }
                      }}
                      style={{ marginRight: 8 }}
                      checked={activeitem.indexOf(item) != -1 ? true : false}
                    />
                    {item}
                  </th>
                </tr>
              ))}
            </tbody>

            <button
              className="btn btn-dark w-100 m-2"
              onClick={() => {
                setPrevilage();
              }}
            >
              Save Changes
            </button>
          </table>
        </div>
      </div>
    </div>
  );
}
export default Previlagemodal;
