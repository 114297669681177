import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
// import Pdf from "react-to-pdf";
import React from "react";
import Paid from "../../images/paids.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Printhead from "../common/printhead2";

function Ginvoice({ id, service }) {
  let history = useHistory();

  const ref = React.createRef();

  const [cname, setCname] = useState("");
  const [cadd, setCadd] = useState("");
  const [pass, setPass] = useState("");
  const [airplane, setAirplane] = useState("");
  const [flight, setFlight] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [gross, setGross] = useState("");
  const [paid, setPaid] = useState("");
  const [fno, setFno] = useState("");
  const [phistory, setPhistory] = useState([]);

  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [edate, setEdate] = useState("");
  const [idate, setIdate] = useState("");
  const [cnum, setCnum] = useState("");
  const [hotel, setHotel] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [date, setDate] = useState("");
  const [medical, setMedical] = useState("");
  const [serial, setSerial] = useState("");
  const [clist, setClist] = useState([]);
  const [paidseal, setPaidseal] = useState(false);

  const [ddate, setDdate] = useState("");

  const [visa, setVisa] = useState("");
  const [aid, setAid] = useState("");

  const options = {
    orientation: "potrait",
    unit: "in",
    format: [8, 12],
  };

  const [role, setRole] = useState(false);
  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].name);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  function gTbase() {
    var total = 0;
    clist.map((item) => {
      if (item.gid == id) {
        total = total + item.base;
      }
    });

    return total;
  }

  function gTgross() {
    var total = 0;
    clist.map((item) => {
      if (item.gid == id) {
        total = total + item.gross;
      }
    });

    return total;
  }

  useEffect(async () => {
    if (service == "international" || service == "domestic") {
      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/invoicefetch",
          {
            id: id,
            service: service,
          }
        )
        .then((res) => {
          setCname(res.data.message[0].cname);
          setCadd(res.data.message[0].cadd);
          setPass(res.data.message[0].passport);
          setAirplane(res.data.message[0].airplane);
          setFlight(res.data.message[0].flight);
          setDeparture(res.data.message[0].departure);
          setArrival(res.data.message[0].arrival);
          setGross(res.data.message[0].gross);
          setPaid(res.data.message[0].paid);
          setPaid(res.data.message[0].paid);
          setSerial(res.data.message[0].serial);
          setIdate(res.data.message[0].idate);

          setAid(res.data.message[0].aid);

          if (res.data.message[0].status == 1) {
            setPaidseal(!paidseal);
          }
        })
        .catch((err) => console.log(err));

      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/historyfetch",
          {
            tid: id,
            stype: "ticket",
          }
        )
        .then((res) => {
          setPhistory(res.data.message);
        })
        .catch((err) => console.log(err));
    }

    if (service == "visa") {
      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/invoicefetch",
          {
            id: id,
            service: service,
          }
        )
        .then((res) => {
          setCname(res.data.message[0].cname);
          setCadd(res.data.message[0].cadd);
          setCnum(res.data.message[0].cnum);
          setPass(res.data.message[0].passport);
          setCountry(res.data.message[0].country);
          setType(res.data.message[0].type);
          setStatus(res.data.message[0].status);
          setGross(res.data.message[0].gross);
          setPaid(res.data.message[0].paid);
          setPaid(res.data.message[0].paid);
          setAid(res.data.message[0].aid);

          if (res.data.message[0].status == "APPROVED") {
            setPaidseal(!paidseal);
          }
        })
        .catch((err) => console.log(err));

      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/historyfetch",
          {
            tid: id,
            stype: "visa",
          }
        )
        .then((res) => setPhistory(res.data.message))
        .catch((err) => console.log(err));
    }

    if (service == "hotel") {
      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/invoicefetch",
          {
            id: id,
            service: service,
          }
        )
        .then((res) => {
          setCname(res.data.message[0].cname);
          setCadd(res.data.message[0].cadd);
          setCnum(res.data.message[0].cnum);
          setPass(res.data.message[0].passport);
          setCountry(res.data.message[0].country);
          setHotel(res.data.message[0].hotel);
          setCin(res.data.message[0].cin);
          setCout(res.data.message[0].cout);
          setGross(res.data.message[0].gross);
          setPaid(res.data.message[0].paid);
          setAid(res.data.message[0].aid);
        })
        .catch((err) => console.log(err));

      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/historyfetch",
          {
            tid: id,
            stype: "hotel",
          }
        )
        .then((res) => {
          setPhistory(res.data.message);
        })
        .catch((err) => console.log(err));
    }

    if (service == "passport") {
      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/invoicefetch",
          {
            id: id,
            service: service,
          }
        )
        .then((res) => {
          setCname(res.data.message[0].cname);
          setCadd(res.data.message[0].cadd);
          setCnum(res.data.message[0].cnum);
          setPass(res.data.message[0].passport);
          setCountry(res.data.message[0].country);
          setHotel(res.data.message[0].hotel);
          setCin(res.data.message[0].cin);
          setCout(res.data.message[0].cout);
          setGross(res.data.message[0].gross);
          setPaid(res.data.message[0].paid);
          setDdate(res.data.message[0].ddate);
          setAid(res.data.message[0].aid);
        })
        .catch((err) => console.log(err));

      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/historyfetch",
          {
            tid: id,
            stype: "passport",
          }
        )
        .then((res) => {
          setPhistory(res.data.message);
        })
        .catch((err) => console.log(err));
    }

    if (service == "manpower") {
      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/invoicefetch",
          {
            id: id,
            service: service,
          }
        )
        .then((res) => {
          console.log(res.data.message);
          setCname(res.data.message[0].cname);
          setCadd(res.data.message[0].cadd);
          setCnum(res.data.message[0].cnum);
          setPass(res.data.message[0].passport);
          setGross(res.data.message[0].gross);
          setPaid(res.data.message[0].paid);
          setDdate(res.data.message[0].ddate);
          setAid(res.data.message[0].aid);
        })
        .catch((err) => console.log(err));

      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/historyfetch",
          {
            tid: id,
            stype: "manpower",
          }
        )
        .then((res) => {
          setPhistory(res.data.message);
        })
        .catch((err) => console.log(err));
    }

    if (service == "umrah" || service == "hajj") {
      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/invoicefetch",
          {
            id: id,
            service: service,
          }
        )
        .then((res) => {
          console.log(res.data.message);
          setCname(res.data.message[0].cname);
          setCadd(res.data.message[0].cadd);
          setCnum(res.data.message[0].cnum);
          setPass(res.data.message[0].passport);
          setGross(res.data.message[0].gross);
          setPaid(res.data.message[0].paid);
          setHotel(res.data.message[0].hname);
          setCin(res.data.message[0].cin);
          setCout(res.data.message[0].cout);
          setFno(res.data.message[0].fno);
          setDeparture(res.data.message[0].departure);
          setArrival(res.data.message[0].arrival);
          setAid(res.data.message[0].aid);
        })
        .catch((err) => console.log(err));

      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/historyfetch",
          {
            tid: id,
            stype: service,
          }
        )
        .then((res) => {
          setPhistory(res.data.message);
        })
        .catch((err) => console.log(err));
    }

    if (service == "medical") {
      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/invoicefetch",
          {
            id: id,
            service: service,
          }
        )
        .then((res) => {
          setCname(res.data.message[0].cname);
          setCadd(res.data.message[0].cadd);
          setCnum(res.data.message[0].cnum);
          setPass(res.data.message[0].passport);

          setVisa(res.data.message[0].visa);

          setMedical(res.data.message[0].medical);

          setDdate(res.data.message[0].ddate);
          setEdate(res.data.message[0].edate);
          setIdate(res.data.message[0].idate);
          setAid(res.data.message[0].aid);
        })
        .catch((err) => console.log(err));

      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/historyfetch",
          {
            tid: id,
            stype: "medical",
          }
        )
        .then((res) => {
          setPhistory(res.data.message);
        })
        .catch((err) => console.log(err));
    }

    if (service == "gticket") {
      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/gticketinvoice",
          {
            id: id,
          }
        )
        .then((res) => {
          setAirplane(res.data.message[0].airpline);
          setFlight(res.data.message[0].flight);
          setDeparture(res.data.message[0].departure);
          setArrival(res.data.message[0].arrival);
          setGross(res.data.message[0].gross);
          setPaid(res.data.message[0].paid);
          setPaid(res.data.message[0].paid);
          setSerial(res.data.message[0].serial);
          setIdate(res.data.message[0].idate);

          setAid(res.data.message[0].aid);

          setClist(res.data.customer);
        })
        .catch((err) => console.log(err));

      await axios
        .post(
          "https://sr-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/historyfetch",
          {
            tid: id,
            stype: "gticket",
          }
        )
        .then((res) => {
          setPhistory(res.data.message);
        })
        .catch((err) => console.log(err));
    }

    // document.getElementById("hit").click();
    // history.goBack();
  }, [id, phistory]);

  useEffect(() => {
    var d = new Date();

    var m = d.getMonth() + 1;
    var day = d.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    if (m < 10) {
      m = "0" + m;
    }

    var x = d.getFullYear() + "/" + m + "/" + day;
    setDate(x);
  }, [id]);

  return (
    <div className="print-hide">
      <div className="container p-0" style={{ position: "relative" }}>
        <i
          className="fa fa-close noprint"
          onClick={() => history.goBack()}
          style={{
            fontSize: 40,
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
          }}
        ></i>

        <br />
        <br />
        <div ref={ref}>
          <Printhead aid={aid} id={id} date={date} />

          {service == "gticket" ? null : (
            <div class="border border-dark my-3 mt-5">
              <div class="border-bottom border-dark">
                <p class="mx-3 m-0 font-weight-bold" style={{ fontSize: 22 }}>
                  Customer Details
                </p>
              </div>
              <div class="row" style={{ fontSize: 20 }}>
                <div class="col-6 border-end border-dark">
                  <p class="mx-3 my-1">
                    <strong>Name:</strong> <span>{cname}</span>
                  </p>
                </div>
                <div class="col-6">
                  <p class="mx-3 my-1">
                    <strong>Passport:</strong> <span>{pass}</span>
                  </p>
                </div>
              </div>
              <div class="border-top border-dark" style={{ fontSize: 20 }}>
                <p class="mx-3 my-1">
                  <strong>Address:</strong> {cadd} <span></span>
                </p>
              </div>
              <div class="border-top border-dark" style={{ fontSize: 20 }}>
                <p class="mx-3 my-1">
                  <strong>Mobile:</strong> {cnum} <span></span>
                </p>
              </div>
            </div>
          )}

          <h4 class="my-2 mt-5 font-weight-bold">Service Details</h4>
          <table class="table text-center table-bordered border-dark">
            <thead>
              <tr style={{ fontSize: 20 }}>
                <th width="10%" scope="col">
                  ID
                </th>
                <th width="25%" scope="col">
                  Service Type
                </th>
                <th width="50%" scope="col">
                  Service details
                </th>
                <th width="15%" scope="col">
                  Ammount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontSize: 20 }}>
                <th>FDI{id}</th>
                <td style={{ textTransform: "uppercase" }}>
                  {service == "gticket" ? "Group Ticket" : service}
                </td>
                <td>
                  {service == "domestic" ? (
                    <div class="row m-1 text-left">
                      <p class="m-0 col-12">
                        Airlines:{" "}
                        <span class="font-weight-bold">{airplane}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Flight: <span class="font-weight-bold">{flight}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Depurture:{" "}
                        <span class="font-weight-bold">{departure}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Arrival: <span class="font-weight-bold">{arrival}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Ticket no:{" "}
                        <span class="font-weight-bold">{serial}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Issue Date:{" "}
                        <span class="font-weight-bold">{idate}</span>
                      </p>
                      <br />
                    </div>
                  ) : null}

                  {service == "international" ? (
                    <div class="row m-1 text-left">
                      <p class="m-0 col-12">
                        Airlines:{" "}
                        <span class="font-weight-bold">{airplane}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Flight: <span class="font-weight-bold">{flight}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Depurture:{" "}
                        <span class="font-weight-bold">{departure}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Arrival: <span class="font-weight-bold">{arrival}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Ticket no:{" "}
                        <span class="font-weight-bold">{serial}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Issue Date:{" "}
                        <span class="font-weight-bold">{idate}</span>
                      </p>
                      <br />
                    </div>
                  ) : null}

                  {service == "visa" ? (
                    <div class="row m-1 text-left">
                      <p class="m-0 col-12">
                        Status: <span class="font-weight-bold">{status}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12 ">
                        Country: <span class="font-weight-bold">{country}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12">
                        Visa Type: <span class="font-weight-bold">{type}</span>
                      </p>
                      <br></br>
                    </div>
                  ) : null}

                  {service == "hotel" ? (
                    <div class="row m-1 text-left">
                      <p class="m-0 col-12">
                        Hotel: <span class="font-weight-bold">{hotel}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12">
                        Country: <span class="font-weight-bold">{country}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12">
                        Check in: <span class="font-weight-bold">{cin}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12">
                        Check out: <span class="font-weight-bold">{cout}</span>
                      </p>
                      <br></br>
                    </div>
                  ) : null}

                  {service == "passport" ? (
                    <div class="row m-1 text-left">
                      <p class="m-0 col-12">
                        Delivery Date:{" "}
                        <span class="font-weight-bold">{ddate}</span>
                      </p>
                      <br></br>
                    </div>
                  ) : null}

                  {service == "manpower" ? (
                    <div class="row m-1 text-left">
                      <p class="m-0 col-12">
                        Delivery Date:{" "}
                        <span class="font-weight-bold">{ddate}</span>
                      </p>
                      <br></br>
                    </div>
                  ) : null}

                  {service == "umrah" || service == "hajj" ? (
                    <div class="row m-1 text-left">
                      <p class="m-0 col-12">
                        Hotel: <span class="font-weight-bold">{hotel}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12">
                        Flight No: <span class="font-weight-bold">{fno}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12">
                        Check in: <span class="font-weight-bold">{cin}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12">
                        Check out: <span class="font-weight-bold">{cout}</span>
                      </p>
                      <br></br>
                      <p class="m-0 col-12">
                        Depurture:{" "}
                        <span class="font-weight-bold">{departure}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Arrival: <span class="font-weight-bold">{arrival}</span>
                      </p>
                      <br />
                    </div>
                  ) : null}

                  {service == "medical" ? (
                    <div class="row m-1 text-left">
                      <p class="m-0 col-12">
                        Issue Date:{" "}
                        <span class="font-weight-bold">{idate}</span>
                      </p>
                      <p class="m-0 col-12">
                        Medical Center:{" "}
                        <span class="font-weight-bold">{medical}</span>
                      </p>
                      <br></br>
                    </div>
                  ) : null}

                  {service == "gticket" ? (
                    <div class="row m-1 text-left">
                      <p
                        class="m-0 col-12"
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        {clist.map((y) => (
                          <tr>
                            <b>{y.gid == id ? y.cname + "  " : null}</b>
                          </tr>
                        ))}
                      </p>
                      <br />

                      <p class="m-0 col-12">
                        Airlines:{" "}
                        <span class="font-weight-bold">{airplane}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Flight: <span class="font-weight-bold">{flight}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Depurture:{" "}
                        <span class="font-weight-bold">{departure}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Arrival: <span class="font-weight-bold">{arrival}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Ticket no:{" "}
                        <span class="font-weight-bold">{serial}</span>
                      </p>
                      <br />
                      <p class="m-0 col-12">
                        Issue Date:{" "}
                        <span class="font-weight-bold">{idate}</span>
                      </p>
                      <br />
                    </div>
                  ) : null}
                </td>
                <td>
                  <p
                    class="m-0 col-12"
                    style={{ borderBottom: "1px solid grey" }}
                  >
                    {clist.map((y) => (
                      <tr>{y.gid == id ? y.gross + " BDT " : null}</tr>
                    ))}
                  </p>
                </td>
              </tr>

              {service == "gticket" ? (
                <>
                  <tr style={{ fontSize: 20 }}>
                    <td colspan="3" class="text-right py-1">
                      <strong>Subtotal</strong>{" "}
                    </td>
                    <td class="py-1">
                      {gTgross()} <span>BDT</span>
                    </td>
                  </tr>

                  {aid == 0 ? (
                    <>
                      <tr style={{ fontSize: 20 }}>
                        <td colspan="3" class="text-right py-1">
                          <strong>Paid</strong>
                        </td>
                        <td class="py-1">
                          {paid} <span>BDT</span>
                        </td>
                      </tr>
                      <tr style={{ fontSize: 20 }}>
                        <td colspan="3" class="text-right py-1">
                          <strong>Due</strong>
                        </td>
                        <td class="py-1">
                          {gTgross() - paid} <span>BDT</span>
                        </td>
                      </tr>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <tr style={{ fontSize: 20 }}>
                    <td colspan="3" class="text-right py-1">
                      <strong>Subtotal</strong>{" "}
                    </td>
                    <td class="py-1">
                      {gross} <span>BDT</span>
                    </td>
                  </tr>

                  {aid == 0 ? (
                    <>
                      <tr style={{ fontSize: 20 }}>
                        <td colspan="3" class="text-right py-1">
                          <strong>Paid</strong>
                        </td>
                        <td class="py-1">
                          {paid} <span>BDT</span>
                        </td>
                      </tr>
                      <tr style={{ fontSize: 20 }}>
                        <td colspan="3" class="text-right py-1">
                          <strong>Due</strong>
                        </td>
                        <td class="py-1">
                          {gross - paid} <span>BDT</span>
                        </td>
                      </tr>
                    </>
                  ) : null}
                </>
              )}
            </tbody>
          </table>

          {aid == 0 ? (
            <div class="border border-dark my-4">
              <div class="border-bottom border-dark">
                <p class="mx-3 m-0 font-weight-bold" style={{ fontSize: 20 }}>
                  Payment History
                </p>
              </div>
              {phistory.map((item) => (
                <p class="mx-3 m-0" style={{ fontSize: 18 }}>
                  {formatDate(item.date)} {item.type} -{" "}
                  <span>
                    {item.amount} BDT{" "}
                    {item.type == "bank" ? "(" + item.bank + ")" : null}
                  </span>
                </p>
              ))}
            </div>
          ) : null}

          <p>* VAT & AIT Included</p>
          <center>{paidseal ? <img src={Paid} width="200" /> : null}</center>
          {/* <div class="d-flex justify-content-between" style={{marginTop:100,fontSize:18}}>
                <p class="border-top border-dark mb-5 border-t"><span class="">Account</span></p>

                <p class="border-top  border-dark mb-5 border-t"><span class="">Manager</span></p>

                <p class="border-top border-dark mb-5 border-t"><span class="">Proprietor</span></p>
            </div> */}
        </div>
      </div>
      <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
        <div class="d-flex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p class="border-top border-dark border-t">
            <span class="">Accounts</span>
          </p>

          <p
            class="border-top  border-dark border-t"
            style={{ marginLeft: 250, marginRight: 250 }}
          >
            <span class="">Manager</span>
          </p>

          <p class="border-top border-dark border-t">
            <span class="">Proprietor</span>
          </p>
        </div>
        <div class="dflex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>
            Developed by mPair Technologies Ltd.
          </p>
          <p
            style={{
              fontSize: 14,
              fontWeight: "bold",
              position: "absolute",
              right: 0,
            }}
          >
            Generated by {role}
          </p>
        </div>
      </footer>
    </div>
  );
}

export default withRouter(Ginvoice);
