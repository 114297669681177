import Logo from "../../images/flydoc.svg";
function Printhead({ aid, id, date }) {
  return (
    <div class="row pt-2 invHeader mx-auto">
      <div class="invleft">
        <div class="d-flex">
          <img src={Logo} class="inimg" alt="..." />
        </div>
        <p class="m-0" style={{ fontSize: 14 }}>
          <i class="fa fa-globe" aria-hidden="true"></i>{" "}
          {process.env.REACT_APP_ADDRESS} <br></br>{" "}
          {process.env.REACT_APP_ADDRESS2}
        </p>
        <p class="m-0" style={{ fontSize: 14 }}>
          <i class="fa fa-phone" aria-hidden="true"></i>{" "}
          <span> {process.env.REACT_APP_PHONE}</span>
        </p>   
      </div>

      <div class="text-right invright">
        <h4 class="font-weight-bold text-center intxt">
          {aid == 0 ? "INVOICE" : "Bill"}
        </h4>
        <p class="mb-0 mt-2 font-weight-bold">FD{id}</p>
        <p class="m-0">{date}</p>
      </div>
    </div>
  );
}

export default Printhead;
