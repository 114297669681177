import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "../navigation2";

import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pdfooter from "../pdfooter";
import Printhead from "../common/printhead";

function Atran(props) {
  var id = props.match.params.id;

  const [cout, setCout] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const [search, setSearch] = useState("");
  const [allven, setAllven] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agent, setAgent] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [ptotal, setPtotal] = useState("");
  var balance = 0;
  var toDay = new Date();

  useEffect(() => {
    var d = new Date();

    var m = d.getMonth() + 1;
    var day = d.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    if (m < 10) {
      m = "0" + m;
    }

    var x = d.getFullYear() + "-" + m;

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/areportfetch",
        {
          uid: id,
        }
      )
      .then((res) => {
        setCout(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/areportfetch2",
        {
          uid: id,
          date: x,
        }
      )
      .then((res) => {
        setPtotal(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => {
        setAllven(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function dcash(x) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/areportfetch2",
        {
          uid: id,
          date: x,
        }
      )
      .then((res) => {
        if (res.data.message != "") {
          setPtotal(res.data.message[0].balance);
        }
        if (res.data.message == "") {
          setPtotal(0);
        }

        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  const daysreport = () => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/areportbetween",
        {
          date1: from,
          date2: to,
          uid: id,
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setCout(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/areportfetch2",
        {
          uid: id,
          date: from,
        }
      )
      .then((res) => {
        if (res.data.message != "") {
          setPtotal(res.data.message[0].balance);
        }
        if (res.data.message == "") {
          setPtotal(0);
        }
      })
      .catch((err) => console.log(err));
  };

  function total() {
    var i = 0;

    return i;
  }

  function printme() {
    window.print();
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <>
      <Navigation2 />

      <Printhead />
      <table style={{ width: "100%" }}>
        <thead style={{ backgroundColor: "white" }}>
          <tr>
            <td class="tranreportheader"></td>
          </tr>
          <tr>
            <td style={{ height: 20 }}></td>
          </tr>
          <br />
        </thead>

        <tbody>
          <tr>
            <td>
              <div class="container">
                <div class="dtran mt-0 pt-0">
                  <div class="text-center">
                    <h5 class="m-0 p-1">Agent Transaction</h5>
                    <h4 class="m-0 p-1">
                      {agents.map((item) => (
                        <>{item.id == id ? item.aname : null}</>
                      ))}
                    </h4>
                    <p class="onprint">
                      Date :{" "}
                      {from == "" && to == ""
                        ? formatDate(toDay)
                        : formatDate(from) + "-" + formatDate(to)}{" "}
                    </p>
                  </div>

                  <hr />

                  <div class="row align-items-center noprint justify-content-center ">
                    <div class="col-lg-10 col-9 p-0 row align-items-center">
                      <div class="col-lg-3 col-3">
                        <input
                          type="date"
                          class="filtBTN-2 m-0 ml-3"
                          onChange={(e) => {
                            setFrom(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-lg-3 col-3">
                        <input
                          type="date"
                          class="filtBTN-2 m-0 ml-3"
                          onChange={(e) => {
                            setTo(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-lg-2">
                        <button
                          class="btn btn-warning noprint"
                          onClick={daysreport}
                          style={{ color: "white" }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>

                    <div class="col-lg-2 col-3">
                      <button
                        class="btn btn-warning noprint"
                        onClick={printme}
                        style={{ color: "white" }}
                      >
                        Download
                      </button>
                    </div>
                  </div>
                  <hr class="noprint" />

                  <div class="prbm">
                    <div class="container">
                      <div class="row align-items-center">
                        <table
                          class="table stranwidth table-bordered"
                          id="table-to-xls3"
                        >
                          <thead class="thead-light">
                            <tr>
                              <td width="05%" scope="col" class="p-1 px-2">
                                SL
                              </td>
                              <td width="10%" scope="col" class="p-1 px-2">
                                Date
                              </td>
                              <td width="40%" scope="col" class="p-1 px-2">
                                Service info
                              </td>
                              <td
                                width="15%"
                                class="text-right p-1 px-2"
                                scope="col"
                              >
                                S. Payable
                              </td>
                              <td
                                width="15%"
                                class="text-right p-1 px-2"
                                scope="col"
                              >
                                Paid
                              </td>
                              <td
                                width="15%"
                                class="text-right p-1 px-2"
                                scope="col"
                              >
                                Balance
                              </td>
                            </tr>
                          </thead>

                          <tr>
                            <td width="05%" scope="col" class="p-1 px-2">
                              0
                            </td>
                            <td width="10%" scope="col" class="p-1 px-2">
                              {formatDate(new Date())}
                            </td>
                            <td width="40%" scope="col" class="p-1 px-2">
                              Opening balance
                            </td>
                            <td
                              width="15%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              0
                            </td>
                            <td
                              width="15%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              0
                            </td>
                            <td
                              width="15%"
                              class="text-right p-1 px-2"
                              scope="col"
                            >
                              {Number(ptotal)}
                            </td>
                          </tr>

                          <tbody>
                            {cout
                              .filter((item, index) => {
                                if (item.date?.includes(search)) {
                                  // balance = balance - item.paid;

                                  return item;
                                }
                              })
                              .map((item, index) => {
                                balance = balance + item.payable;
                                balance = balance - item.paid;
                                return (
                                  <tr>
                                    <td width="5%" scope="row" class="p-1 px-2">
                                      {index + 1}
                                    </td>
                                    <td
                                      width="10%"
                                      scope="row"
                                      class="p-1 px-2"
                                    >
                                      {formatDate(item.date)}
                                    </td>
                                    <td
                                      width="40%"
                                      class="p-1 px-2"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item.des}
                                    </td>
                                    <td width="15%" class="text-right p-1 px-2">
                                      {item.payable}
                                    </td>
                                    <td width="15%" class="text-right p-1 px-2">
                                      {item.paid}
                                    </td>
                                    <td width="15%" class="text-right p-1 px-2">
                                      {balance}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <br /> <br />
                    <br /> <br />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td style={{ height: "120px" }}></td>
          </tr>
        </tfoot>
      </table>

      <footer
        class="footer"
        style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      >
        <div class="d-flex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p class="border-top border-dark border-t">
            <span class="">Accounts</span>
          </p>

          <p
            class="border-top  border-dark border-t"
            style={{ marginLeft: 250, marginRight: 250 }}
          >
            <span class="">Manager</span>
          </p>

          <p class="border-top border-dark border-t">
            <span class="">Proprietor</span>
          </p>
        </div>
        <Pdfooter />
      </footer>
    </>
  );
}

export default withRouter(Atran);
