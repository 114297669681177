import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";

import searchb from "../../images/search.png";
import axios from "axios";
import Footer from "../footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import B2bpaymodal from "../modals/b2bpaymodal";
import Delb2bmodal from "../modals/delb2bmodal";

function Agent(props) {
  var type = props.match.params.type;
  const [alldata, setAlldata] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState("");
  const [paid, setPaid] = useState(0);
  const [did, setDid] = useState("");

  const [uid, setUid] = useState(0);
  const [phistory, setPhistory] = useState([]);
  const [stype, setStype] = useState("agent");
  const [banks, setBanks] = useState([]);
  const [abank, setAbank] = useState("");
  const [name, setName] = useState("");

  const [brunch, setBrunch] = useState("");
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [ano, setAno] = useState("");

  const [airplane, setAirplane] = useState("");
  const [fno, setFno] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [price, setPrice] = useState("");
  const [quan, setQuan] = useState("");

  const [purchasehis, setPurchasehis] = useState([]);

  const [afetch, setAfetch] = useState("");
  const [bfetch, setBfetch] = useState("");
  const [b2bmodalactive, setB2bmodalactive] = useState(false);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAlldata(res.data.message))
      .catch((err) => console.log(err));
  }, [alldata]);

  function delagent() {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/delagent",
        {
          id: did,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delit() {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/delpur",
        {
          id: afetch,
        }
      )
      .then((res) => sindata(bfetch))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function fetchall(x) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchall2",
        {
          term: x,
        }
      )
      .then((res) => {
        setTotal(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  function paypurchase() {
    var departure = de1 + " " + de2;
    var arrival = arr1 + " " + arr2;

    const data = new FormData();
    data.append("airplane", airplane);
    data.append("fno", fno);
    data.append("departure", departure);
    data.append("arrival", arrival);
    data.append("quan", quan);
    data.append("price", price);
    data.append("uid", uid);

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/paypurchase",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    sindata(uid);
  }

  function sindata(x) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/sinpurchase",
        {
          id: x,
        }
      )
      .then((res) => setPurchasehis(res.data.message))
      .catch((err) => console.log(err));
  }

  return (
    <>
      <ToastContainer />

      <div
        class="modal fade bankModal"
        id="bankModal1"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="bankModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content bankmodalCont">
            <div class="modal-header">
              <h5 class="modal-title" id="bankModalLabel1">
                {bank}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col">
                  <p class="infotext my-2">
                    Branch Name: <span class="font-weight-bold">{brunch}</span>
                  </p>
                  <p class="infotext my-2">
                    Account Name:{" "}
                    <span class="font-weight-bold">{account}</span>
                  </p>
                  <p class="infotext my-2">
                    Account Number: <span class="font-weight-bold">{ano}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div className="col-lg-2"></div>

          <div class="col-lg-10 col-12 my-4">
            <div class="row search-mb">
              <div class="col-lg-12 col-12 border px-0">
                <div class="border-bottom bg-light mx-0 d-flex align-items-center">
                  <h4 class="p-2 mb-0">Agent Seller</h4>
                  <Link to="/adagent">
                    <div class="btn btn-darkg mx-3">
                      <div class="m-0">Add New</div>
                    </div>
                  </Link>
                </div>

                <div class="row mx-auto bg-light p-2 border-bottom">
                  <div class="d-flex form-group has-search col-lg-12 col-12 m-0 p-0">
                    <img src={searchb} class="sChooseIcon2" alt="..." />
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control searchbox"
                      required="required"
                      placeholder="Search Agent"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>

                {alldata
                  .filter((item) => {
                    if (
                      item.aname.toLowerCase().includes(search.toLowerCase()) ||
                      item.anum.toLowerCase().includes(search.toLowerCase()) ||
                      item.type
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <div class="card border-0 rounded-0">
                      <div class="card-body p-0 m-0">
                        <div class="row m-0 p-0 py-2 border-bottom border-top">
                          <div class="col-lg-6 col-12 pl-3 p-1 my-auto">
                            <p class="font-weight-bold c_name m-0 my-1">
                              {item.aname}
                            </p>
                            <p class="m-0 my-1 cusData ">{item.anum}</p>
                            <p class="m-0 my-1 cusData" row="2">
                              {item.aadd}
                            </p>
                          </div>

                          <div class="col-lg-3 col-6 my-auto">
                            <div
                              onClick={() => {
                                setUid(item.id);
                                fetchall(item.id);
                                setPaid(item.paid);
                                setName(item.aname);

                                setB2bmodalactive(true);
                              }}
                              class="btn statusbttn w-100 my-1"
                              type="button"
                              data-toggle="modal"
                              data-target="#Vpaymentstatus"
                            >
                              Payment Status
                            </div>

                            <Link
                              class="btn transbttn w-100 my-1"
                              to={"/atran/" + item.id}
                            >
                              Transaction Report
                            </Link>
                          </div>
                          <div class="col-lg-3 col-6 my-auto">
                            <div class="dropdown show">
                              <a
                                class="btn upbttn w-100 my-1"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Edit Agent
                              </a>

                              <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <Link
                                  to={"/updatea/" + item.id}
                                  class="dropdown-item"
                                  href="#"
                                >
                                  Update Info
                                </Link>
                                <div class="dropdown-divider"></div>
                                <button
                                  class="dropdown-item"
                                  data-toggle="modal"
                                  data-target="#dellog"
                                  onClick={() => setDid(item.id)}
                                >
                                  Delete Agent
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <B2bpaymodal
        uid={uid}
        paid={paid}
        setPaid={setPaid}
        name={name}
        stype={stype}
        active={b2bmodalactive}
        close={setB2bmodalactive}
      />

      <Delb2bmodal did={did} stype={stype} />

      <Footer />
    </>
  );
}

export default withRouter(Agent);
