import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import trash from "../../images/trash.png";
import searchb from "../../images/search.png";
import waiting from "../../images/flightWaiting.png";
import client from "../../images/placeholder.svg";
import Land from "../../images/flightLand.png";
import done from "../../images/done.png";
import axios from "axios";
import Navigation2 from "../navigation2";
import Sidebar from "../common/sidebar";
import Paymentmodal from "../modals/paymentmodal";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Gticket() {
  const [alldata, setAlldata] = useState([]);
  const [search, setSearch] = useState("");
  const [csearch, setCsearch] = useState("regular");
  const [allven, setAllven] = useState([]);
  const [agents, setAgents] = useState([]);

  const [uname, setUname] = useState("");
  const [upay, setUpay] = useState("");
  const [paid, setPaid] = useState("");
  const [uid, setUid] = useState(0);
  const [view, setView] = useState("cash");
  const [role, setRole] = useState("");
  const [allg, setAllg] = useState([]);
  const [phistory, setPhistory] = useState([]);
  const [status, setStatus] = useState("");
  const [ptype, setPtype] = useState("");
  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [tamount, setTamount] = useState(0);
  const [banks, setBanks] = useState([]);
  const [mload, setMload] = useState(false);
  const [stype, setStype] = useState("gticket");
  const [abank, setAbank] = useState("");
  const [clist, setClist] = useState([]);
  const [acit, setAcit] = useState("");
  const [acid, setAcid] = useState("");
  const [paymodal, setPaymondal] = useState(false);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  function delgticket() {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delgticket",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function upstatus(x) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upgstatus",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function historyfetch(x, y) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/historyfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => setPhistory(res.data.message))
      .catch((err) => console.log(err));
  }

  function gTgross(x) {
    var total = 0;

    clist.map((item) => {
      if (item.gid == x) {
        total = total + item.gross;
      }
    });
    setUpay(total);
  }

  function payhistory() {
    setMload(true);
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    document.getElementById("spfrm").reset();

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("abank", abank);
    data.append("name", uname);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/payhistory",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        setMload(false);
      })
      .catch((err) => console.log(err));

    setPaid(tamount);
    historyfetch(uid, stype);

    setHbank("");
    setAmount("");
    setTamount(0);
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchAllg",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setClist(res.data.clist);
        setAlldata(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [alldata]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => setAllven(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].role);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <Paymentmodal
        active={paymodal}
        uname={uname}
        upay={upay}
        paid={paid}
        setPaid={setPaid}
        uid={uid}
        stype={stype}
        serial={""}
        status={status}
        close={setPaymondal}
      />

      <div
        class="modal fade deleteModal animate__animated animate__slideInDown"
        id="delmodel"
        tabindex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ top: 20 }} role="document">
          <div class="modal-content datadelM">
            <div class="modal-body">
              <p class="m-0 py-3">Do you want to Delete the Group Ticket?</p>
            </div>
            <div class="modal-footer py-2">
              <button
                type="button"
                class="btn btn-secondary btndlet"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary btnyes"
                onClick={() => {
                  delgticket();
                }}
                data-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade cpayment_status"
        data-keyboard="false"
        data-backdrop="static"
        id="Cpaymentstatus"
        tabindex="1"
        role="dialog"
        aria-labelledby="CpaymentModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog cPaymentDialog" role="document">
          <div class="modal-content">
            <div class="mHeader">
              <p class="modal-title pModalheadear" id="CpaymentModalLabel">
                Payment Status
              </p>
            </div>
            <div class="pModalbody">
              <div class="row CastPay"></div>

              {status == 0 ? (
                <>
                  <div class="row">
                    <div class="col-4 fontTypA">
                      <p class="mb-2">Total Payable</p>
                    </div>

                    <div class="col-8">
                      <p class="mb-2 font-weight-bold">{upay} BDT</p>
                    </div>
                  </div>

                  <div class="row CastPay">
                    <div class="col-4 fontTypA">
                      <p class="m-0">Total Paid</p>
                    </div>

                    <div class="col-8">
                      <p class="mb-2 font-weight-bold">{paid} BDT</p>
                      {phistory.map((item) => (
                        <p class="pAmountHist m-0">
                          {formatDate(item.date)} {item.type} -{" "}
                          <span>
                            {item.amount} BDT{" "}
                            {item.type == "bank" ? "(" + item.bank + ")" : null}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div class="row">
                    <div class="col-4 fontTypA">
                      <p class="mb-2 font-weight-bold">Total Paid</p>
                    </div>

                    <div class="col-8">
                      {phistory.map((item) => (
                        <p class="pAmountHist m-0">
                          {formatDate(item.date)} {item.type} -{" "}
                          <span>
                            {item.amount} BDT{" "}
                            {item.type == "bank" ? "(" + item.bank + ")" : null}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>

            {status == 1 ? (
              <>
                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn closeBtn"
                    data-dismiss="modal"
                    onClick={() => {
                      document.getElementById("pills-home-tab").click();
                      setView("cash");
                      setPtype("cash");
                      setHbank("");
                      setAmount("");
                      setTamount(0);
                    }}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : (
              <>
                <p class="font-weight-bold pModalbody">New Payment</p>
                <div class="row justify-content-center">
                  <ul
                    class="nav nav-pills text-center p-0 col-lg-8 col-8"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item w-50">
                      <a
                        class="nav-link active"
                        onClick={() => {
                          setView("cash");
                          setPtype("Cash");
                        }}
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Cash
                      </a>
                    </li>
                    <li class="nav-item w-50">
                      <a
                        class="nav-link"
                        onClick={() => {
                          setView("bank");
                          setPtype("bank");
                        }}
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Bank
                      </a>
                    </li>
                  </ul>

                  <form class="mb-4 mt-2 col-lg-8 col-10" id="spfrm">
                    {view == "cash" ? (
                      <div class="row align-items-center justify-content-center my-4">
                        <div class="col-lg-4 col-4 form-check form-check-inline checkboxSpread">
                          <label for="_0" class="form-check-label fontTypA">
                            Cash Payment
                          </label>
                        </div>
                        <div class="col-lg-6 col-6 d-flex">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => {
                              setAmount(e.target.value);
                              setTamount(Number(paid) + Number(e.target.value));
                            }}
                          />
                          <p class="ml-2 fontTypA">BDT</p>
                        </div>
                      </div>
                    ) : null}

                    {view == "bank" ? (
                      <div class="row align-items-center justify-content-center my-4">
                        <div class="col-lg-3 col-4 form-check form-check-inline checkboxSpread">
                          <label for="_0" class="form-check-label fontTypA">
                            Bank Payment
                          </label>
                        </div>
                        <div class="col-lg-4 col-6 d-flex">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => {
                              setAmount(e.target.value);
                              setTamount(Number(paid) + Number(e.target.value));
                            }}
                          />
                          <p class=" ml-2 fontTypA">BDT</p>
                        </div>
                        <div class="col-lg-4 marginTop col-11 d-flex">
                          <div class="input-group">
                            <select
                              id="inputState"
                              class="form-select form-control"
                              onChange={(e) => setHbank(e.target.value)}
                            >
                              <option class="form-control" selected value="">
                                Choose Bank...
                              </option>
                              {banks.map((item) => (
                                <option value={item.id + "/" + item.bank}>
                                  {item.bank}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </form>
                </div>

                <div class="modal-footer justify-content-between">
                  <button
                    type="button"
                    class="btn closeBtn"
                    data-dismiss="modal"
                    onClick={() => {
                      document.getElementById("pills-home-tab").click();
                      setView("cash");
                      setPtype("cash");
                      setHbank("");
                      setAmount("");
                      setTamount(0);
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn paymentUpdate"
                    disabled={mload ? true : false}
                    onClick={() => {
                      payhistory();
                    }}
                  >
                    {mload ? "Loading" : "Update"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div class="container-fluid mb-5 pb-2">
        <div class="row">
          <div className="col-lg-2 col-9">
            <Sidebar />
          </div>

          <div class="col-lg-10 col-12 my-4">
            <div class="row search-mb">
              <div class="col-lg-12 col-12 border px-0">
                <div class="border-bottom bg-light mx-0 d-flex align-items-center">
                  <h4 class="p-2 mb-0">Group Ticket</h4>
                  <Link to="/addgroupticket">
                    <div class="btn btn-darkg mx-3">
                      <div class="m-0">Add Group Ticket</div>
                    </div>
                  </Link>
                </div>
                <div class="row mx-auto bg-light p-2 border-bottom">
                  <div class="d-flex form-group has-search col-lg-9 col-9 m-0 p-0">
                    <img src={searchb} class="sChooseIcon2" alt="..." />
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control searchbox"
                      required="required"
                      placeholder="Search PNR"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  <div class="col-lg-3 col-3 pr-0">
                    <div class="col-12">
                      <input
                        type="date"
                        class="filtBTN-3 m-0 ml-3"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="row mx-auto">
                  <ul
                    class="nav nav-pills p-0 text-center col-lg-12"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item col-lg-6 col-6 px-0">
                      <a
                        class="nav-link active"
                        id="pills-home-tab"
                        onClick={() => setCsearch("regular")}
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Regular
                      </a>
                    </li>
                    <li class="nav-item col-lg-6 col-6 px-0">
                      <a
                        class="nav-link"
                        id="pills-profile-tab"
                        onClick={() => setCsearch("agent")}
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="true"
                      >
                        Agent
                      </a>
                    </li>
                  </ul>
                  <hr />
                </div>

                {alldata.map((item) => (
                  <>
                    {item.ctype == csearch ? (
                      <div class="card border-0 rounded-0">
                        <div class="card-body p-0 m-0">
                          <div class="row m-0 p-0 border-bottom border-top">
                            <div class="col-lg-6 col-12 dflight_data my-auto p-2 pl-3">
                              <p class="m-0 cusData">
                                Vendor :{" "}
                                <span class="font-weight-bold">
                                  {allven.map((y) => (
                                    <>{y.id == item.vendor ? y.vname : null}</>
                                  ))}
                                </span>
                              </p>
                              <p class="m-0 cusData">
                                Serial/PNR :{" "}
                                <span class="font-weight-bold">{item.pnr}</span>
                              </p>
                              <p class="m-0 cusData">
                                Airlines :{" "}
                                <span class="font-weight-bold">
                                  {item.airline}
                                </span>
                              </p>

                              <p class="m-0 cusData">
                                Departure :{" "}
                                <span class="font-weight-bold">
                                  {item.departure}
                                </span>
                              </p>
                              <p class="m-0 cusData">
                                Arrival :{" "}
                                <span class="font-weight-bold">
                                  {item.arrival}
                                </span>
                              </p>
                              <p class="m-0 cusData">
                                Issue Date :{" "}
                                <span class="font-weight-bold">
                                  {item.idate}
                                </span>
                              </p>

                              <p class="m-0 cusData">
                                <Link
                                  to={"/sgticket/" + item.id}
                                  className="text-danger"
                                >
                                  See full profile{" "}
                                  <i className="fa fa-angle-right"></i>
                                </Link>
                              </p>
                            </div>

                            <div class="col-lg-4 col-8 my-auto borderRight">
                              {item.ctype == "agent" ? (
                                <div class="anbtn w-100 my-1">
                                  {getagent(item.aid)}
                                </div>
                              ) : (
                                <div
                                  class="btn statusbttn w-100 my-1"
                                  type="button"
                                  data-toggle="modal"
                                  // data-target="#Cpaymentstatus"
                                  onClick={() => {
                                    gTgross(item.id);
                                    setPaid(item.paid);
                                    setUid(item.id);
                                    historyfetch(item.id, "gticket");
                                    setUname(item.pnr);
                                    setPaymondal(true);
                                  }}
                                >
                                  Payment Status
                                </div>
                              )}
                              {item.done == 1 ? (
                                <button class="btn upbttn w-100 my-1" disabled>
                                  Update Info
                                </button>
                              ) : (
                                <Link
                                  to={"/updategt/" + item.id}
                                  class="btn upbttn w-100 my-1"
                                >
                                  Update Info
                                </Link>
                              )}
                            </div>

                            <div class="col-lg-2 col-4 my-auto">
                              {item.status == 0 ? (
                                <div class="row col-12 m-0 p-0 align-items-center">
                                  <div class="sStatus-2 w-100 text-center my-1">
                                    <img
                                      src={waiting}
                                      class="staticon-2"
                                      alt="..."
                                    />
                                  </div>
                                  <div class=" sStatusOK-2 w-100 text-center my-1">
                                    <img
                                      onClick={() => upstatus(item.id)}
                                      src={done}
                                      class="staticon-2"
                                      alt="Flight Done"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div class="sStatusOK-3 w-100 text-center my-1">
                                  <img
                                    src={Land}
                                    class="staticon-2 mt-2"
                                    alt="..."
                                  />
                                  <p>
                                    Flight<br></br>Done
                                  </p>
                                </div>
                              )}

                              {role == "admin" ? (
                                <div
                                  class="sStatus-R w-100 text-center my-1"
                                  onClick={(e) => setAcid(item.id)}
                                  data-toggle="modal"
                                  data-target="#delmodel"
                                >
                                  <img
                                    src={trash}
                                    class="staticon-2"
                                    alt="..."
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gticket;
