import React, { useEffect, useState } from "react";
import Logo from "../images/flydoc.svg";
import admin from "../images/admin.svg";
import setings from "../images/settings.png";
import logout_logo from "../images/logout.svg";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "./common/loading";

function Navigation() {
  let history = useHistory();

  const [role, setRole] = useState(false);
  const [load, setLoad] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      axios
        .post(
          "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
          {
            token: localStorage.getItem("token"),
          }
        )
        .then((res) => {
          setLoad(false);
          if (res.data.message == "") {
            localStorage.removeItem("token");
            history.push("/");
          } else {
            setRole(res.data.message[0].role);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <>
      <Loading load={load} />
      <nav class="navbar navbar-expand-lg">
        <Link
          to="/dashboard"
          class="navbar-brand d-flex align-items-center px-3"
          style={{ backgroundColor: "#f9f9f9" }}
        >
          <img src={Logo} alt="logo" class="flyDocLogo" />
        </Link>
        {/* <h3 class="text-white mt-2 agencyh">{process.env.REACT_APP_TITLE}</h3> */}
        <ul class="navbar-nav ml-auto ">
          <li class="navbar-item">
            <Link to="/profile">
              <img src={setings} alt="" class="settingsnav navIcons mx-3 p-0" />
            </Link>
            <button type="button" class="btn btn-secondary nav_btn p-0">
              <a href="#" class="d-flex justify-content-end">
                <p class="admin_text m-0">{role}</p>
                <img src={admin} alt="" class="account navIcons mr-1 p-0" />
              </a>
            </button>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.removeItem("token");
                history.push("/");
              }}
            >
              <img src={logout_logo} alt="" class="account-2 navIcons  ml-2" />
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}
export default Navigation;
