import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import umrah from "../../images/Umrah.png";
import manpower from "../../images/ManPower.png";
import airlines from "../../images/airlinesv.png";
import agent from "../../images/Agent.png";
import hajj2 from "../../images/Hajj.svg";
import medical2 from "../../images/Medical.svg";
import settings from "../../images/settings.png";
import gticket from "../../images/gTicket.png";
import pservice from "../../images/package_service.png";

function Sidebar() {
  let history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      history.push("/");
    }
  }, [localStorage.getItem("token")]);

  var url = window.location.href;
  url = url.replace("http://localhost:3000/", "");

  const handleClick = (event) => {
    console.log("hey");
  };

  const [role, setRole] = useState(false);
  const [previlage, setPrevilage] = useState("");

  const [services, setServices] = useState([
    {
      service: "Package Services",
      srt: "Packages",
      to: "/customer/packages",
      url: "customer/packages",
      img: pservice,
    },
    {
      service: "Domestic Ticket",
      srt: "Domestic",
      to: "/customer/domestic",
      url: "customer/domestic",
      img: "../images/domTicket.png",
    },
    {
      service: "International Ticket",
      srt: "International",
      to: "/customer/international",
      url: "customer/international",
      img: "../images/intTicket.svg",
    },
    {
      service: "Group Ticket",
      to: "/gticket",
      srt: "Group Ticket",
      url: "gticket",
      img: gticket,
    },

    {
      service: "Visa",
      to: "/customer/visa",
      srt: "Visa",
      url: "customer/visa",
      img: "../images/visa.svg",
    },
    {
      service: "Hotel",
      to: "/customer/hotel",
      srt: "Hotel",
      url: "customer/hotel",
      img: "../images/hotel.svg",
    },
    {
      service: "Umrah",
      to: "/customer/umrah",
      srt: "Umrah",
      url: "customer/umrah",
      img: require("../../images/Umrah.png"),
    },
    {
      service: "Hajj",
      to: "/customer/hajj",
      srt: "Hajj",
      url: "customer/hajj",
      img: hajj2,
    },
    {
      service: "Medical",
      to: "/customer/medical",
      srt: "Medical",
      url: "customer/medical",
      img: medical2,
    },
    {
      service: "Manpower",
      to: "/customer/manpower",
      srt: "Manpower",
      url: "customer/manpower",
      img: manpower,
    },
    {
      service: "Passport",
      to: "/customer/passport",
      srt: "Passport",
      url: "customer/passport",
      img: require("../../images/Passport.png"),
    },
    {
      service: "Agent",
      to: "/b2b/agent",
      srt: "Agent",
      url: "b2b/agent",
      img: agent,
    },
    {
      service: "Vendor",
      to: "/b2b/vendor",
      srt: "Vendor",
      url: "b2b/vendor",
      img: "../images/vendor.svg",
    },
    {
      service: "Airlines Vendor",
      to: "/b2b/avendor",
      srt: "Airlines Vendor",
      url: "b2b/avendor",
      img: airlines,
    },
    {
      service: "Accounts",
      to: "/accounts",
      srt: "Accounts Management",
      url: "accounts",
      img: "../images/accounts.svg",
    },
    {
      service: "Staff",
      to: "/staff",
      srt: "Staff Management",
      url: "staff",
      img: "../images/staf.svg",
    },
    {
      service: "Settings",
      to: "/profile",
      srt: "Agency Setting",
      url: "profile",
      img: settings,
    },
  ]);
  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].role);
        setPrevilage(res.data.previlage[0]?.previlage);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  return (
    <div className="col-lg-2 col-9 left-bar-0 noprint">
      <div class="p-0">
        <div class="pt-2 px-4">
          {services.map((item) => (
            <a href="#" class="modal-text">
              <Link
                onClick={handleClick.bind(this)}
                to={
                  previlage
                    .toLocaleLowerCase()
                    .includes(item.srt.toLocaleLowerCase())
                    ? item.to
                    : "#"
                }
              >
                <div
                  class={
                    url == item.url
                      ? "row align-items-center side_nav active "
                      : "row align-items-center side_nav "
                  }
                >
                  <div class="col-2">
                    <img src={item.img} class="dropmenuIcon float-end" alt="" />
                  </div>
                  <div class="col-10">
                    <p
                      class=""
                      style={{
                        fontSize: 14,
                        fontWeight: 300,
                        marginTop: 7,
                        marginBottom: 7,
                      }}
                    >
                      {item.service}
                    </p>
                  </div>
                </div>
              </Link>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
