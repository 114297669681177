import { useEffect } from "react";
import { useState } from "react";
import {
  useHistory,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import manpower from "../../images/ManPower.png";
import medical2 from "../../images/Medical.svg";
import axios from "axios";
import Loading from "../common/loading";
function Packages({
  steps,
  setSteps,
  id,
  packages,
  update,
  setUpdate,
  lastid,
  ctype,
  setCtype,
  setAid,
}) {
  let history = useHistory();

  const [active, setActive] = useState("ticket");
  const [show, setShow] = useState(false);
  const [agents, setAgents] = useState([]);

  const [vendors, setVendors] = useState([]);

  const [airplane, setAirplane] = useState("");
  const [pnr, setPnr] = useState("");
  const [tserial, setTserial] = useState("");
  const [arloca, setArloca] = useState("");
  const [ardate, setArdate] = useState("");
  const [vendor, setVendor] = useState("");
  const [country, setCountry] = useState("");
  const [vtype, setVtype] = useState("");
  const [sdate, setSdate] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [mcenter, setMcenter] = useState("");
  const [ddate, setDdate] = useState("");
  const [bmnumber, setBmnumber] = useState("");
  const [snumber, setSnumber] = useState("");
  const [enroll, setEnroll] = useState("");
  const [base, setBase] = useState("");
  const [load, setLoad] = useState(false);
  const [hide, setHide] = useState(false);

  const [services, setServices] = useState([
    {
      service: "Domestic Ticket",
      srt: "Domestic",
      to: "/customer/domestic",
      url: "customer/domestic",
      img: "../images/domTicket.png",
    },
    {
      service: "International Ticket",
      srt: "International",
      to: "/customer/international",
      url: "customer/international",
      img: "../images/intTicket.svg",
    },

    {
      service: "Visa",
      to: "/customer/visa",
      srt: "Visa",
      url: "customer/visa",
      img: "../images/visa.svg",
    },
    {
      service: "Hotel",
      to: "/customer/hotel",
      srt: "Hotel",
      url: "customer/hotel",
      img: "../images/hotel.svg",
    },
    {
      service: "Medical",
      to: "/customer/medical",
      srt: "Medical",
      url: "customer/medical",
      img: medical2,
    },
    {
      service: "Manpower",
      to: "/customer/manpower",
      srt: "Manpower",
      url: "customer/manpower",
      img: manpower,
    },
    {
      service: "Passport",
      to: "/customer/passport",
      srt: "Passport",
      url: "customer/passport",
      img: require("../../images/Passport.png"),
    },
  ]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchalltypevendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function handleSubmit() {
    console.log(mcenter + ddate);
    setLoad(true);
    const data = new FormData();
    data.append("packageId", Number(lastid));
    data.append("airplane", airplane);
    data.append("pnr", pnr);
    data.append("tserial", tserial);
    data.append("arloca", arloca);
    data.append("ardate", ardate);
    data.append("vendor", vendor);
    data.append("base", base);
    data.append("country", country);
    data.append("vtype", vtype);
    data.append("sdate", sdate);
    data.append("hname", hname);
    data.append("cin", cin);
    data.append("mcenter", mcenter);
    data.append("ddate", ddate);
    data.append("bmnumber", bmnumber);
    data.append("snumber", snumber);
    data.append("enroll", enroll);
    data.append("service", active);
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/addNew",
        data
      )
      .then((res) => {
        setUpdate(update + 1);
        setHide(true);
        setLoad(false);
        document.getElementById("frm").reset();
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchalltypevendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Loading load={load} />

      <div>
        {packages.map((item) => (
          <>
            {item.service.toLocaleLowerCase().includes("ticket") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Airplane :</b> {item.airplane}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>PNR :</b> {item.pnr}
                  </p>
                  <p class="mb-0">
                    <b>Ticket Serial :</b> {item.tserial}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("visa") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Country :</b> {item.country}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>Visa Type :</b> {item.vtype}
                  </p>
                  <p class="mb-0">
                    <b>Stamping Date :</b> {item.sdate} {lastid}{" "}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("hotel") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Country :</b> {item.country}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>Hotel Name :</b> {item.hname}{" "}
                  </p>
                  <p class="mb-0">
                    <b>Check-In :</b> {item.cin}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("medical") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Medical Center Name :</b> {item.mcenter}{" "}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>Delivery Date :</b> {item.ddate}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("manpower") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Country :</b> {item.country}{" "}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>BMET Number :</b> {item.bmnumber}
                  </p>
                  <p class="mb-0">
                    <b>Delivery Date :</b> {item.ddate}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("passport") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Slip Number :</b> {item.snumber}{" "}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>Enrollment Date :</b> {item.enroll}
                  </p>
                </div>
              </div>
            ) : null}
          </>
        ))}
      </div>

      <div class="bg-light border mx-auto mb-0 px-3">
        <form id="frm">
          {hide ? null : (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Customer Type
                </label>
                <div class="col-lg-6 col-12 mt-2">
                  <div class="form-check form-check-inline">
                    <input
                      name="ctype"
                      id="fit"
                      type="radio"
                      class="form-check-input"
                      value="regular"
                      required=""
                      onChange={(e) => {
                        setCtype(e.target.value);
                        setShow(false);
                      }}
                    />
                    <label for="_0" class="form-check-label infotext mr-2">
                      Regular
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      name="ctype"
                      id="fit"
                      type="radio"
                      class="form-check-input"
                      value="agent"
                      required=""
                      onChange={(e) => {
                        setCtype(e.target.value);
                        setShow(true);
                      }}
                    />
                    <label for="_0" class="form-check-label infotext mr-2">
                      From Agent
                    </label>
                  </div>
                </div>
              </div>

              {ctype == "agent" ? (
                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-lg-10 col-12">
                    <div class="input-group">
                      {ctype == "agent" ? (
                        <select
                          class="form-control"
                          onChange={(e) => setAid(e.target.value)}
                        >
                          <option>Choose Agent</option>
                          {agents.map((item) => (
                            <option value={item.id}>{item.aname}</option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}

          <div class="form-group row py-1 mt-3">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Select Service
            </label>
            <div class="col-lg-10 col-12">
              <select
                onChange={(e) => {
                  setActive(e.target.value);
                }}
                className="form-control"
              >
                {services.map((item) => (
                  <option>{item.service}</option>
                ))}
              </select>
            </div>
          </div>

          {active.toLocaleLowerCase().includes("ticket") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Airplane
                </label>
                <div class="col-lg-10 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => {
                      setAirplane(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  PNR
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => {
                      setPnr(e.target.value);
                    }}
                  />
                </div>
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Ticket Serial
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="text"
                      name="text"
                      type="text"
                      placeholder=""
                      class="form-control"
                      required=""
                      onChange={(e) => {
                        setTserial(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Flight Sector
                </label>
                <div class="col-lg-2 col-2">
                  <input
                    type="text"
                    class="form-control"
                    required=""
                    placeholder="Ex. DAC/DXB"
                    onChange={(e) => {
                      setArloca(e.target.value);
                    }}
                  ></input>
                </div>
                <div class="col-lg-2 col-3">
                  <input
                    type="date"
                    class="form-control"
                    required=""
                    onChange={(e) => {
                      setArdate(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  vendor
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <select
                      onChange={(e) => {
                        setVendor(e.target.value);
                      }}
                      className="form-control"
                    >
                      {vendors.map((item) => (
                        <option value={item.id}>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-12 infotext col-form-label"
                >
                  Buying Price
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    type="text"
                    class="form-control"
                    required=""
                    onChange={(e) => {
                      setBase(Number(base) + Number(e.target.value));
                    }}
                  ></input>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("visa") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Country
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                  />
                </div>
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Visa Type
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setVtype(e.target.value)}
                  />
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Stamping Date
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setSdate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Vendor
                </label>
                <div class="col-lg-4 col-2">
                  <select
                    onChange={(e) => {
                      setVendor(e.target.value);
                    }}
                    className="form-control"
                  >
                    {vendors.map((item) => (
                      <option value={item.id}>{item.vname}</option>
                    ))}
                  </select>
                </div>
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      required=""
                      onChange={(e) => {
                        setBase(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("hotel") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Country
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>

                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Hotel Name
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setHname(e.target.value)}
                  />
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Check-In
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="date"
                      name="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setCin(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Vendor
                </label>
                <div class="col-lg-4 col-2">
                  <div class="input-group">
                    <select
                      onChange={(e) => setVendor(e.target.value)}
                      className="form-control"
                    >
                      {vendors.map((item) => (
                        <option>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      required=""
                      onChange={(e) => setBase(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("manpower") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Country
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>

                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  BMET Number
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setBmnumber(e.target.value)}
                  />
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Delivery Date
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="date"
                      name="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setDdate(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Vendor
                </label>
                <div class="col-lg-4 col-2">
                  <div class="input-group">
                    <select
                      onChange={(e) => setVendor(e.target.value)}
                      className="form-control"
                    >
                      {vendors.map((item) => (
                        <option>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      required=""
                      onChange={(e) => setBase(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("medical") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Medical Center
                </label>
                <div class="col-lg-10 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setMcenter(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Delivery Date
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="date"
                      name="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setDdate(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Vendor
                </label>
                <div class="col-lg-4 col-2">
                  <div class="input-group">
                    <select
                      onChange={(e) => setVendor(e.target.value)}
                      className="form-control"
                    >
                      {vendors.map((item) => (
                        <option>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      required=""
                      onChange={(e) => setBase(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("passport") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Slip Number
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setSnumber(e.target.value)}
                  />
                </div>

                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Enrollment Date
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="date"
                      name="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setEnroll(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      required=""
                      onChange={(e) => setBase(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </form>

        <div class="form-group row py-2">
          <div className="col-lg-6"></div>
          <div class="col-lg-3">
            <button
              onClick={() => {
                handleSubmit();
              }}
              class="w-100 btn btn-primary"
            >
              Add More Service
            </button>
          </div>
          <div class="col-lg-3 col-12">
            <button
              onClick={() => {
                handleSubmit();
                setSteps(Number(steps) + 1);
              }}
              class="w-100 btn btn-primary"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Packages);
