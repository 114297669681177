import { useState, useEffect } from "react";
import axios from "axios";
import Navigation2 from "../navigation2";
import { useHistory } from "react-router-dom";

function AddAgent() {
  let history = useHistory();

  const [atype, setAtype] = useState("");
  const [aname, setAname] = useState("");
  const [anum, setAnum] = useState("");
  const [aadd, setAadd] = useState("");
  const [type, setType] = useState("");
  const [bank1, setBank1] = useState("");
  const [account1, setAccount1] = useState("");
  const [ano1, setAno1] = useState("");
  const [brunch1, setBrunch1] = useState("");

  const [bank2, setBank2] = useState("");
  const [account2, setAccount2] = useState("");
  const [ano2, setAno2] = useState("");
  const [brunch2, setBrunch2] = useState("");

  const [bank3, setBank3] = useState("");
  const [account3, setAccount3] = useState("");
  const [ano3, setAno3] = useState("");
  const [brunch3, setBrunch3] = useState("");

  const [bank4, setBank4] = useState("");
  const [account4, setAccount4] = useState("");
  const [ano4, setAno4] = useState("");
  const [brunch4, setBrunch4] = useState("");

  const [bank5, setBank5] = useState("");
  const [account5, setAccount5] = useState("");
  const [ano5, setAno5] = useState("");
  const [brunch5, setBrunch5] = useState("");
  const [paid, setPaid] = useState("");

  const [bno, setBno] = useState(1);

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/adagent",
        {
          aname: aname,
          anum: anum,
          aadd: aadd,
          atype: atype,
          paid: paid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        console.log(res.data.message);
        history.goBack();
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />

      <br></br>
      <div class="container">
        <div class="addVendor">
          <h3>Add Agent</h3>
          <hr />
          <form onSubmit={handleSubmit}>
            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-12 col-form-label infotext">
                Agent Name
              </label>
              <div class="col-lg-9 col-12">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                  onChange={(e) => setAname(e.target.value)}
                />
              </div>
            </div>
            <div class="form-group row py-2">
              <label for="text" class="col-lg-3 col-12 col-form-label infotext">
                Agent Mobile No
              </label>
              <div class="col-lg-9 col-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">+88</div>
                  </div>
                  <input
                    id="text"
                    name="text"
                    type="text"
                    class="form-control"
                    required="required"
                    placeholder="018XXXXXXXX"
                    onChange={(e) => setAnum(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="textarea" class="col-lg-3 col-form-label infotext">
                Opening Balance
              </label>
              <div class="col-lg-9">
                <input
                  id="textarea"
                  name="textarea"
                  cols="40"
                  rows="2"
                  class="form-control"
                  required="required"
                  onChange={(e) => setPaid(e.target.value)}
                ></input>
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="textarea" class="col-lg-3 col-form-label infotext">
                Agent Address
              </label>
              <div class="col-lg-9">
                <textarea
                  id="textarea"
                  name="textarea"
                  cols="40"
                  rows="2"
                  class="form-control"
                  required="required"
                  onChange={(e) => setAadd(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div class="form-group row py-3 justify-content-end">
              <div class="col-lg-9">
                <div class="d-grid gap-2 text-center">
                  <button class="btn btn-primary w-100 submitbtn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddAgent;
