import { useState, useEffect } from "react";
import formatDate from "../functions/timeformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import DelpaymentModal from "./delpayment";
import Loading from "../common/loading";

function Staffreport({ active, close, staffId }) {
  const [report, setReport] = useState([]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/getCareof",
        {
          staffId: staffId,
        }
      )
      .then((res) => {
        setReport((item) => [
          ...item,
          ...res.data.ticket,
          ...res.data.visa,
          ...res.data.umrah,
        ]);
      })
      .catch((err) => console.log(err));
  }, [staffId]);
  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <div class="mHeader">
          <i
            onClick={() => {
              close(false);
              document.getElementById("pills-home-tab").click();
            }}
            className="fa fa-close cusIcon"
          ></i>

          <p class="modal-title pModalheadear" id="CpaymentModalLabel">
            Staff Report
          </p>
        </div>

        <div className="container">
          {report.map((item) => (
            <div className="card m-2 p-4">
              <div>
                <i className="fa fa-user"></i> {item.cname}
              </div>
              <div className="d-flex justify-content-between">
                <p>
                  <i className="fa fa-phone"></i> {item.cnum}{" "}
                </p>
                <p>
                  <i className="fa fa-calendar"></i> {formatDate(item.date)}
                </p>
                <p>
                  <i className="fa fa-check"></i> {item.service}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Staffreport;
