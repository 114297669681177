import { useState, useEffect } from "react";
import axios from "axios";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Updateve(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [vtype, setVtype] = useState("");
  const [vname, setVname] = useState("");
  const [vnum, setVnum] = useState("");
  const [vadd, setVadd] = useState("");
  const [type, setType] = useState("");
  const [bank1, setBank1] = useState("");
  const [account1, setAccount1] = useState("");
  const [ano1, setAno1] = useState("");
  const [brunch1, setBrunch1] = useState("");

  const [bank2, setBank2] = useState("");
  const [account2, setAccount2] = useState("");
  const [ano2, setAno2] = useState("");
  const [brunch2, setBrunch2] = useState("");

  const [bank3, setBank3] = useState("");
  const [account3, setAccount3] = useState("");
  const [ano3, setAno3] = useState("");
  const [brunch3, setBrunch3] = useState("");

  const [bank4, setBank4] = useState("");
  const [account4, setAccount4] = useState("");
  const [ano4, setAno4] = useState("");
  const [brunch4, setBrunch4] = useState("");

  const [bank5, setBank5] = useState("");
  const [account5, setAccount5] = useState("");
  const [ano5, setAno5] = useState("");
  const [brunch5, setBrunch5] = useState("");

  const [bno, setBno] = useState(1);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprev4",
        {
          id: id,
        }
      )
      .then((res) => {
        setVname(res.data.message[0].vname);
        setVnum(res.data.message[0].vnum);
        setVadd(res.data.message[0].vadd);
        setBank1(res.data.message[0].bank1);
        setAccount1(res.data.message[0].account1);
        setAno1(res.data.message[0].ano1);
        setBrunch1(res.data.message[0].brunch1);

        setBank2(res.data.message[0].bank2);
        setAccount2(res.data.message[0].account2);
        setAno2(res.data.message[0].ano2);
        setBrunch2(res.data.message[0].brunch2);

        setBank3(res.data.message[0].bank3);
        setAccount3(res.data.message[0].account3);
        setAno3(res.data.message[0].ano3);
        setBrunch3(res.data.message[0].brunch3);

        setBank4(res.data.message[0].bank4);
        setAccount4(res.data.message[0].account4);
        setAno4(res.data.message[0].ano4);
        setBrunch4(res.data.message[0].brunch4);

        setBank5(res.data.message[0].bank5);
        setAccount5(res.data.message[0].account5);
        setAno5(res.data.message[0].ano5);
        setBrunch5(res.data.message[0].brunch5);

        document.getElementById("inputState").value = res.data.message[0].vtype;

        document.getElementById(res.data.message[0].type).checked = true;

        setType(res.data.message[0].type);
        setVtype(res.data.message[0].vtype);
      })
      .catch((err) => console.log(err));
  }, [id]);

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/upvendor",
        {
          vtype: vtype,
          vname: vname,
          vnum: vnum,
          vadd: vadd,
          type: type,
          bank1: bank1,
          account1: account1,
          ano1: ano1,
          brunch1: brunch1,

          bank2: bank2,
          account2: account2,
          ano2: ano2,
          brunch2: brunch2,

          bank3: bank3,
          account3: account3,
          ano3: ano3,
          brunch3: brunch3,
          id: id,
        }
      )
      .then((res) => {
        if (res.data.message == null) {
          toast.success("Vendor updated", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });
        }

        history.goBack();
      })

      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />

      <ToastContainer />

      <br></br>
      <div class="container">
        <div class="addVendor">
          <h3>Update Vendor Info</h3>
          <hr />
          <form onSubmit={handleSubmit}>
            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Vendor Name
              </label>
              <div class="col-lg-9 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                  value={vname}
                  onChange={(e) => setVname(e.target.value)}
                />
              </div>
            </div>
            <div class="form-group row py-2">
              <label for="text" class="col-lg-3 col-4 col-form-label infotext">
                Mobile No
              </label>
              <div class="col-lg-9 col-8">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">+88</div>
                  </div>
                  <input
                    id="text"
                    name="text"
                    type="text"
                    class="form-control"
                    required="required"
                    value={vnum}
                    onChange={(e) => setVnum(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div class="form-group row py-2">
              <label
                for="textarea"
                class="col-lg-3 col-4 col-form-label infotext"
              >
                Address
              </label>
              <div class="col-lg-9 col-8">
                <textarea
                  id="textarea"
                  name="textarea"
                  cols="40"
                  rows="2"
                  class="form-control"
                  required="required"
                  value={vadd}
                  onChange={(e) => setVadd(e.target.value)}
                ></textarea>
              </div>
            </div>
            {/* <div class="form-group row py-2">
              <label class="col-lg-3 col-4 infotext">Service type</label>
              <div class="col-lg-9 col-8">
                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="serviceSelect"
                    id="ticket"
                    type="radio"
                    class="form-check-input"
                    value="ticket"
                    required="required"
                    onClick={(e) => setType(e.target.value)}
                  />
                  <label for="_0" class="form-check-label infotext">
                    Ticket
                  </label>
                </div>

                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="serviceSelect"
                    id="hotel"
                    type="radio"
                    class="form-check-input"
                    value="hotel"
                    required="required"
                    onClick={(e) => setType(e.target.value)}
                  />
                  <label for="_0" class="form-check-label infotext">
                    Hotel
                  </label>
                </div>

                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="serviceSelect"
                    id="manpower"
                    type="radio"
                    class="form-check-input"
                    value="manpower"
                    required="required"
                    onClick={(e) => setType(e.target.value)}
                  />
                  <label for="_0" class="form-check-label infotext">
                    Manpower
                  </label>
                </div>

                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="serviceSelect"
                    id="medical"
                    type="radio"
                    class="form-check-input"
                    value="medical"
                    required="required"
                    onClick={(e) => setType(e.target.value)}
                  />
                  <label for="_0" class="form-check-label infotext">
                    Medical
                  </label>
                </div>

                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="serviceSelect"
                    id="umrah"
                    type="radio"
                    class="form-check-input"
                    value="umrah"
                    required="required"
                    onClick={(e) => setType(e.target.value)}
                  />
                  <label for="_0" class="form-check-label infotext">
                    Umrah
                  </label>
                </div>

                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="serviceSelect"
                    id="hajj"
                    type="radio"
                    class="form-check-input"
                    value="hajj"
                    required="required"
                    onClick={(e) => setType(e.target.value)}
                  />
                  <label for="_0" class="form-check-label infotext">
                    Hajj
                  </label>
                </div>

                <div class="form-check form-check-inline checkboxSpread">
                  <input
                    name="serviceSelect"
                    id="visa"
                    type="radio"
                    class="form-check-input"
                    value="visa"
                    required="required"
                    onClick={(e) => setType(e.target.value)}
                  />
                  <label for="_0" class="form-check-label infotext">
                    Visa
                  </label>
                </div>
              </div>
            </div> */}

            {/* Bank info */}
            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Bank Name 1
              </label>
              <div class="col-lg-9 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                  value={bank1}
                  onChange={(e) => setBank1(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Account Name
              </label>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                  value={account1}
                  onChange={(e) => setAccount1(e.target.value)}
                />
              </div>
              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8 marginB">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                  placeholder="Account Number"
                  value={ano1}
                  onChange={(e) => setAno1(e.target.value)}
                />
              </div>

              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                  placeholder="Branch Name"
                  value={brunch1}
                  onChange={(e) => setBrunch1(e.target.value)}
                />
              </div>
            </div>

            {/* Bank info */}

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Bank Name 2
              </label>
              <div class="col-lg-9 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  value={bank2}
                  onChange={(e) => setBank2(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Account Name 2
              </label>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  value={account2}
                  onChange={(e) => setAccount2(e.target.value)}
                />
              </div>
              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8 marginB">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  placeholder="Account Number"
                  value={ano2}
                  onChange={(e) => setAno2(e.target.value)}
                />
              </div>

              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  placeholder="Branch"
                  value={brunch2}
                  onChange={(e) => setBrunch2(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Bank Name 3
              </label>
              <div class="col-lg-9 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  value={bank3}
                  onChange={(e) => setBank3(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Account Name 3
              </label>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  value={account3}
                  onChange={(e) => setAccount3(e.target.value)}
                />
              </div>

              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8 marginB">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  placeholder="Account Number"
                  value={ano3}
                  onChange={(e) => setAno3(e.target.value)}
                />
              </div>

              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  placeholder="Branch"
                  value={brunch3}
                  onChange={(e) => setBrunch3(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Bank Name 4
              </label>
              <div class="col-lg-9 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  value={bank3}
                  onChange={(e) => setBank4(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Account Name 4
              </label>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  value={account3}
                  onChange={(e) => setAccount4(e.target.value)}
                />
              </div>

              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8 marginB">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  placeholder="Account Number"
                  value={ano3}
                  onChange={(e) => setAno4(e.target.value)}
                />
              </div>

              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  placeholder="Branch"
                  value={brunch3}
                  onChange={(e) => setBrunch4(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Bank Name 5
              </label>
              <div class="col-lg-9 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  value={bank3}
                  onChange={(e) => setBank5(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-lg-3 col-4 col-form-label infotext">
                Account Name 5
              </label>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  value={account3}
                  onChange={(e) => setAccount5(e.target.value)}
                />
              </div>

              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8 marginB">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  placeholder="Account Number"
                  value={ano3}
                  onChange={(e) => setAno5(e.target.value)}
                />
              </div>

              <div class="col-4 desktophidden"></div>
              <div class="col-lg-3 col-8">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required=""
                  placeholder="Branch"
                  value={brunch3}
                  onChange={(e) => setBrunch5(e.target.value)}
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-2"></div>
            </div>

            <div class="form-group row py-3 justify-content-end">
              <div class="col-lg-9 col-12">
                <div class="d-grid gap-2 text-center">
                  <button class="btn btn-primary w-100 submitbtn">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(Updateve);
