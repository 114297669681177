import axios from "axios";
import { useState, useEffect } from "react";
import {
  useHistory,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import Navigation2 from "../navigation2";

function Addgroupcus(props) {
  var id = props.match.params.id;
  const [tuser, setTuser] = useState([]);

  let history = useHistory();
  const [user, setUser] = useState([]);

  const [cname, setCname] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [next, setNext] = useState(false);
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");

  function clearFeild() {
    setCname("");
    setBase("");
    setGross("");
  }
  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchgcustomer",
        {
          id: id,
        }
      )
      .then((res) => {
        setUser(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [user]);

  function gcustomer() {
    const data = new FormData();
    data.append("cname", cname);
    data.append("base", base);
    data.append("gross", gross);
    data.append("gid", id);

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/gcustomer",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          setCname("");
          setBase("");
          setGross("");
        }
      })
      .catch((err) => console.log(err));
  }

  function gupdate() {
    const data = new FormData();
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("base", getTbase());
    data.append("gross", getTgross());
    data.append("gid", id);

    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/gupdate",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          history.push("/");
        }
      });
  }

  function getTbase() {
    var total = 0;

    user.map((item) => {
      total = total + item.base;
    });

    return total;
  }

  function getTgross() {
    var total = 0;

    user.map((item) => {
      total = total + item.gross;
    });

    return total;
  }

  return (
    <>
      <Navigation2 />

      {next ? (
        <div className="container" style={{ marginTop: 90 }}>
          <div class="form-group row py-1">
            <label for="" class="col-lg-2 col-4 col-form-label infotext">
              Buying Price
            </label>
            <div class="col-lg-2 col-8 me-auto">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                required="required"
                value={getTbase()}
                disabled={true}
              />
            </div>
          </div>

          <div class="form-group row py-1">
            <label for="" class="col-lg-2 col-4 col-form-label infotext">
              Selling Price
            </label>
            <div class="col-lg-2 col-8 me-auto">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                required="required"
                value={getTgross()}
                disabled={true}
              />
            </div>

            <label
              for=""
              class="col-lg-2 col-4 marginTop col-form-label infotext"
            >
              VAT & AIT
            </label>
            <div class="col-lg-2 col-8 marginTop me-auto">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                placeholder="Ex. 0.03"
                required=""
                value={vat}
                onChange={(e) => {
                  var p =
                    Number(getTgross()) -
                    (Number(getTbase()) + Number(e.target.value));
                  setVat(e.target.value);
                  setProfit(p);
                }}
              />
            </div>
            <label
              for=""
              class="col-lg-2 col-4 marginTop col-form-label infotext"
            >
              Profit
            </label>
            <div class="col-lg-2 col-8 marginTop me-auto">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                required=""
                value={profit}
                readOnly
                onChange={(e) => setProfit(e.target.value)}
              />
            </div>

            <br />
            <br />
            <button
              class="btn btn-primary bg-light w-100"
              style={{ color: "black", marginLeft: 12 }}
              onClick={() => {
                gupdate();
              }}
            >
              Save <i className="fa fa-right"></i>
            </button>
          </div>
        </div>
      ) : (
        <div className="container" style={{ marginTop: 90 }}>
          {user.map((item) => (
            <div className="card m-2 p-2">
              <p>{item.cname}</p>
              <p>
                <b>Base : </b> {item.base} <b>Gross : </b> {item.gross}{" "}
              </p>
            </div>
          ))}

          <div class="form-group row py-2">
            <label for="" class="col-2 col-form-label infotext">
              Customer Name
            </label>
            <div class="col-10">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                value={cname}
                onChange={(e) => setCname(e.target.value)}
              />
            </div>
          </div>

          <div class="form-group row py-2">
            <label for="" class="col-lg-2 col-form-label infotext">
              Buying Price
            </label>
            <div class="col-lg-2">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                value={base}
                onChange={(e) => setBase(e.target.value)}
              />
            </div>

            <label for="" class="col-lg-2 col-form-label infotext">
              Selling Price
            </label>

            <div class="col-lg-2">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                value={gross}
                onChange={(e) => setGross(e.target.value)}
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-2"></div>
            <div class="col-10 text-center">
              <button
                class="btn btn-primary bg-light"
                style={{ color: "black" }}
                onClick={() => {
                  gcustomer();
                }}
              >
                Add More Customer
              </button>

              <button
                class="btn btn-primary bg-light"
                style={{ color: "black", marginLeft: 12 }}
                onClick={() => {
                  gcustomer();
                  setNext(true);
                }}
              >
                Next <i className="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(Addgroupcus);
