import { useState, useEffect } from "react";
import formatDate from "../functions/timeformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import DelpaymentModal from "./delpayment";
import Loading from "../common/loading";

function B2bpaymodal({
  uid,
  paid,
  setPaid,
  name,
  stype,
  active,
  close,
  b1,
  b2,
  b3,
}) {
  const [phistory, setPhistory] = useState([]);
  const [ptype, setPtype] = useState("cash");
  const [amount, setAmount] = useState("");
  const [tamount, setTamount] = useState(0);
  const [bank, setBank] = useState("");
  const [banks, setBanks] = useState([]);
  const [abank, setAbank] = useState("");
  const [hbank, setHbank] = useState("");
  const [activeId, setActiveId] = useState("");
  const [coun, setCoun] = useState(0);
  const [delpayModal, setDelpayModal] = useState(false);
  const [mload, setMload] = useState(false);

  const [pdate, setPdate] = useState("");
  const [pnote, setPnote] = useState("");

  function payhistory() {
    setMload(true);
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      setMload(false);
      return false;
    }

    if (ptype == "bank") {
      if (stype == "vendor") {
        if (abank == "") {
          toast.warn("Bank name is empty", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });
          setMload(false);
          return false;
        }
      } else {
        if (hbank == "") {
          toast.warn("Bank name is empty", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });
          setMload(false);
          return false;
        }
      }
    }

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("abank", abank);
    data.append("name", name);
    data.append("date", pdate);
    data.append("note", pnote);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/payhistoryb2b",
        data
      )
      .then((res) => {
        setMload(false);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));

    setPaid(tamount);

    setAmount("");
    setAbank("");
    historyfetch(uid, stype);
  }

  function historyfetch(x, y) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/agentfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => setPhistory(res.data.message))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/agentfetch",
        {
          tid: uid,
          stype: stype,
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setPhistory(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [uid, coun]);

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <Loading load={mload} />
        <DelpaymentModal
          active={delpayModal}
          activeId={activeId}
          coun={coun}
          setCoun={setCoun}
        />

        <div class="" role="document">
          <div class="">
            <div class="mHeader">
              <i
                onClick={() => {
                  close(false);

                  setPtype("cash");
                  setHbank("");
                  setAmount("");
                  setTamount(0);
                  document.getElementById("cash-tab").click();
                }}
                className="fa fa-close cusIcon"
              ></i>

              <p class="modal-title pModalheadear" id="VpaymentModalLabel">
                Payment Status
              </p>
            </div>
            <div class="pModalbody my-4">
              <div class="row">
                <div class="col-4 fontTypA">
                  <p class="mb-2">
                    {stype == "agent"
                      ? "Agent"
                      : stype == "vendor"
                      ? "Vendor"
                      : ""}{" "}
                    Name
                  </p>
                </div>
                <div class="col-8">
                  <p class="mb-2 font-weight-bold">{name}</p>
                </div>
              </div>

              {/* <div class="row CastPay">
                <div class="col-4 fontTypA">
                  <p class="mb-2">Total Deposite</p>
                </div>
                <div class="col-8">
                  <p class="mb-2 font-weight-bold">{paid} BDT</p>
                </div>
              </div> */}
            </div>

            <form class="mb-4" id="spfrm">
              <p class="font-weight-bold pModalbody">
                {stype == "agent"
                  ? "Receive New"
                  : stype == "vendor"
                  ? "Make New"
                  : ""}{" "}
                Payment
              </p>
              <div class="row pModalbody justify-content-center border">
                <ul class="nav nav-pills text-center p-0 col-lg-12 col-10">
                  <li class="nav-item w-50">
                    <a
                      onClick={(e) => setPtype("cash")}
                      class="nav-link active cashpV"
                      data-toggle="pill"
                      id="cash-tab"
                      href="#cashPayment"
                      role="tab"
                      aria-controls="pills-cash"
                      aria-selected="true"
                    >
                      Cash Payment
                    </a>
                  </li>
                  <li class="nav-item w-50">
                    <a
                      onClick={(e) => setPtype("bank")}
                      class="nav-link"
                      data-toggle="pill"
                      href="#bankPayment"
                      role="tab"
                      aria-controls="pills-bank"
                      aria-selected="false"
                    >
                      Bank Payment
                    </a>
                  </li>
                </ul>

                <div class="tab-content col-lg-12 col-10 mt-2">
                  <div
                    class="tab-pane active bg-white"
                    id="cashPayment"
                    role="tabpanel"
                  >
                    <div class="row justify-content-center align-items-center my-4">
                      <div class="col-lg-6 col-6">
                        <p class="m-0">Date</p>
                      </div>
                      <div class="col-lg-6 col-6 d-flex">
                        <input
                          id=""
                          name=""
                          type="date"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setPdate(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div class="row justify-content-center align-items-center my-4">
                      <div class="col-lg-6 col-6">
                        <p class="m-0">Cash Amount</p>
                      </div>
                      <div class="col-lg-6 col-6 d-flex">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTamount(Number(paid) + Number(e.target.value));
                          }}
                        />
                        <p class="ml-2 fontTypA">BDT</p>
                      </div>
                    </div>

                    <div class="row justify-content-center align-items-center my-4">
                      <div class="col-lg-6 col-6">
                        <p class="m-0">Note</p>
                      </div>
                      <div class="col-lg-6 col-6 d-flex">
                        <textarea
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setPnote(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane bg-white my-4"
                    id="bankPayment"
                    role="tabpanel"
                    aria-labelledby="bank-tab"
                  >
                    <div class="row justify-content-center align-items-center my-4">
                      <div class="col-lg-6 col-6">
                        <p class="m-0">Date</p>
                      </div>
                      <div class="col-lg-6 col-6 d-flex">
                        <input
                          id=""
                          name=""
                          type="date"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setPdate(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div class="row justify-content-center align-items-center my-2">
                      <div class="col-lg-6 col-6">
                        <p class="m-0">Bank Amount</p>
                      </div>
                      <div class="col-lg-6 col-6 d-flex">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTamount(Number(paid) + Number(e.target.value));
                          }}
                        />
                        <p class="ml-2 fontTypA">BDT</p>
                      </div>
                    </div>

                    <div class="row justify-content-center align-items-center my-4">
                      <div class="col-lg-6 col-6">
                        <p class="m-0">Note</p>
                      </div>
                      <div class="col-lg-6 col-6 d-flex">
                        <textarea
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setPnote(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {/* Agency Bank */}

                    <div class="row justify-content-center align-items-center my-2">
                      <div class="col-lg-6 col-6">
                        <p class="m-0">Agency Bank</p>
                      </div>
                      <div class="col-6 d-flex">
                        <div class="input-group">
                          <select
                            id="inputState"
                            class="form-select form-control"
                            onChange={(e) => setHbank(e.target.value)}
                          >
                            <option class="form-control" selected value="">
                              Choose Bank...
                            </option>
                            {banks.map((item) => (
                              <option value={item.id + "/" + item.bank}>
                                {item.bank}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    {stype == "vendor" ? (
                      <div class="row justify-content-center align-items-center my-2">
                        <div class="col-lg-6 col-6">
                          <p class="m-0">Vendor Bank</p>
                        </div>
                        <div class="col-6 d-flex">
                          <div class="input-group">
                            <select
                              id="inputState"
                              class="form-select form-control"
                              onChange={(e) => setAbank(e.target.value)}
                            >
                              <option class="form-control" selected value="">
                                Choose Bank...
                              </option>
                              <option>{b1}</option>
                              {b2 != "" ? <option>{b2}</option> : null}
                              {b3 != "" ? <option>{b3}</option> : null}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {/* vendor Bank */}
                  </div>
                </div>
              </div>
            </form>

            <div class="pModalbody justify-content-between">
              <button
                type="button"
                class="btn paymentUpdate w-100"
                onClick={() => {
                  payhistory();
                  document.getElementById("spfrm").reset();
                }}
              >
                Update
              </button>
            </div>

            <div className="pModalbody  mt-4">
              <p class="border-bottom font-weight-bold mb-0">Payment history</p>
              <table class="table border" style={{ fontSize: 13 }}>
                <thead class="bg-light">
                  <tr>
                    <th scope="col" class="py-1">
                      Date
                    </th>
                    <th scope="col" class="py-1">
                      Payment type
                    </th>
                    <th scope="col" class="py-1 text-center">
                      Amount
                    </th>
                    <th scope="col" class="py-1 text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {phistory.map((item, index) => (
                    <tr>
                      <td>{formatDate(item.date)}</td>
                      <td>
                        {item.type}{" "}
                        {item.type == "bank" ? "(" + item.bank + ")" : null}(
                        {item.note})
                      </td>
                      <td class="text-center">{item.amount}</td>
                      <td class="text-center">
                        <i
                          data-toggle="modal"
                          data-target="#delModelinside"
                          onClick={() => {
                            setActiveId(item.id);
                          }}
                          className="fa fa-trash"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default B2bpaymodal;
