import { withRouter, Link } from "react-router-dom";
function Footer() {
  var url = window.location.href;
  url = url.replace("http://localhost:3000/", "");

  return (
    <div class="btnav">
      <Link
        to={"/dashboard"}
        class={url == "dashboard" ? "bta activec" : "bta"}
      >
        <i
          class={
            url == "dashboard"
              ? "fa-solid fa-house activeb"
              : "fa-solid fa-house"
          }
        ></i>
      </Link>
      <Link
        to={"/adcustomer"}
        class={url == "adcustomer" ? "bta activec" : "bta"}
      >
        <i
          class={
            url == "adcustomer"
              ? "fa-solid fa-circle-plus activeb"
              : "fa-solid fa-circle-plus"
          }
        ></i>
      </Link>
      <button
        onClick={() => {
          document.getElementById("sidebar").style.width = "100%";
        }}
        class="bta"
      >
        <i class="fa-solid fa-square-poll-vertical"></i>
      </button>
    </div>
  );
}

export default Footer;
