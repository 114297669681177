import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function IncomeModal({ active, uid, coun, setCoun, close }) {
  const [banks, setBanks] = useState([]);
  const [view, setView] = useState("cash");
  const [type, setType] = useState("");
  const [des, setDes] = useState("");
  const [tamount, setTamount] = useState(0);
  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, [banks]);

  function payhistory2() {
    if (type == "") {
      toast.warn("Type is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    var sp = "income";
    var ab = "";
    var un = "";
    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("uid", uid);
    data.append("stype", sp);
    data.append("abank", des);
    data.append("name", "Bank Transfer");
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/payhistory",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    setAmount("");
    setHbank("");
    setType("");

    setCoun(coun + 1);
  }

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <div
          class="cpayment_status"
          data-keyboard="false"
          data-backdrop="static"
          id="expense2"
          tabindex="1"
          role="dialog"
          aria-labelledby="CpaymentModalLabel"
          aria-hidden="true"
        >
          <div class="" role="document">
            <div class="">
              <div class="mHeader">
                <i
                  onClick={() => {
                    close(false);
                  }}
                  className="fa fa-close cusIcon"
                ></i>

                <p class="modal-title pModalheadear" id="CpaymentModalLabel">
                  Additional Income
                </p>
              </div>
              <div class="pModalbody py-4">
                <form id="spfrm3">
                  <div class="row align-items-center mb-4">
                    <div class="col-lg-4">
                      <p class="m-0">Income Type</p>
                    </div>
                    <div class="col-lg-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Ex. Passport"
                        onChange={(e) => setType(e.target.value)}
                      />
                    </div>
                  </div>

                  <div class="row align-items-center">
                    <div class="col-lg-4">
                      <p class="m-0">Income Details</p>
                    </div>
                    <div class="col-lg-8">
                      <textarea
                        type="text"
                        rows="3"
                        class="form-control"
                        required
                        placeholder="Ex. Passport Serviece Charge.."
                        onChange={(e) => setDes(e.target.value)}
                      />
                    </div>
                  </div>
                </form>

                <p class="border-bottom mt-4 font-weight-bold">Add Amount</p>

                <div class="row mx-auto border justify-content-center">
                  <ul
                    class="nav nav-pills text-center p-0 col-lg-12 col-10"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item w-50">
                      <a
                        class="nav-link active"
                        onClick={() => {
                          setView("cash");
                          setPtype("Cash");
                        }}
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Cash
                      </a>
                    </li>
                    <li class="nav-item w-50">
                      <a
                        class="nav-link"
                        onClick={() => {
                          setView("bank");
                          setPtype("bank");
                        }}
                        id="pills-profile-tab"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Bank
                      </a>
                    </li>
                  </ul>

                  <form class="mb-2 mt-2 col-lg-12 col-12" id="spfrm4">
                    {view == "cash" ? (
                      <div class="row align-items-center justify-content-center my-4">
                        <div class="col-lg-6 col-4">
                          <label for="_0" class="form-check-label fontTypA">
                            Cash Payment
                          </label>
                        </div>
                        <div class="col-lg-6 col-6 d-flex">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                          />
                          <p class="ml-2 fontTypA">BDT</p>
                        </div>
                      </div>
                    ) : null}

                    {view == "bank" ? (
                      <div class="row align-items-center justify-content-center my-4">
                        <div class="col-lg-6 col-4">
                          <label for="_0" class="form-check-label fontTypA">
                            Bank Payment
                          </label>
                        </div>
                        <div class="col-lg-6 col-6 d-flex">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                          />
                          <p class=" ml-2 fontTypA">BDT</p>
                        </div>

                        <div class="col-lg-6 mt-2"></div>

                        <div class="col-lg-6 mt-2 col-10">
                          <div class="input-group">
                            <select
                              id="inputState"
                              class="form-select form-control"
                              onChange={(e) => setHbank(e.target.value)}
                            >
                              <option class="form-control" selected>
                                Choose Bank
                              </option>
                              {banks.map((item) => (
                                <option value={item.id + "/" + item.bank}>
                                  {item.bank}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
              <div class="pModalbody">
                <button
                  type="button"
                  class="btn paymentUpdate w-100"
                  onClick={() => {
                    document.getElementById("spfrm3").reset();
                    document.getElementById("spfrm4").reset();
                    payhistory2();
                  }}
                >
                  Add Income
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default IncomeModal;
