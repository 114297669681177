import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import ticketI from "../../images/ticketsI.png";
import visaI from "../../images/visaI.png";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Printhead from "../printhead";

function Hotel(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");

  const [vendor, setVendor] = useState("");
  const [pval, setPval] = useState("");
  const [cof, setCof] = useState("");
  const [type, setType] = useState("");
  const [visa, setVisa] = useState("");
  const [vval, setVval] = useState("");
  const [hotel, setHotel] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [ttype, setTtype] = useState("");
  const [serial, setSerial] = useState("");

  const [photo, setCphoto] = useState("");
  const [cserial, setCserial] = useState("");

  const [edate, setEdate] = useState("");
  const [note, setNote] = useState("");

  const [aid, setAid] = useState("");
  const [agents, setAgents] = useState([]);

  const [doc1, setDoc1] = useState("");
  const [doc2, setDoc2] = useState("");
  const [doc3, setDoc3] = useState("");
  const [doc4, setDoc4] = useState("");
  const [doc5, setDoc5] = useState("");

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");

  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");

  const [vendors, setVendors] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => setStaff(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  function getStaff(x) {
    var i = "";
    staff.map((item) => {
      if (item.id == x) {
        i = item.sname;
      }
    });

    return i;
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprev3",
        {
          id: id,
        }
      )
      .then((res) => {
        setCphoto(res.data.message[0].photo);
        setCserial(res.data.message[0].cserial);
        setCname(res.data.message[0].cname);
        setCnum(res.data.message[0].cnum);
        setCadd(res.data.message[0].cadd);
        setPassport(res.data.message[0].passport);
        setNid(res.data.message[0].nid);
        setCin(res.data.message[0].cin);
        setCout(res.data.message[0].cout);
        setHotel(res.data.message[0].hotel);
        setPval(res.data.message[0].pval);
        setVval(res.data.message[0].vval);
        setVisa(res.data.message[0].visa);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSerial(res.data.message[0].serial);
        setVendor(res.data.message[0].vendor);

        setEdate(res.data.message[0].edate);
        setAid(res.data.message[0].aid);
        setNote(res.data.message[0].note);

        setCof(res.data.message[0].cof);
        document.getElementById("inputven").value = res.data.message[0].vendor;
      })
      .catch((err) => console.log(err));
  }, [id]);

  function handleSubmit(e) {
    e.preventDefault();

    const data = new FormData();

    data.append("passport", passport);
    data.append("nid", nid);
    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("visa", visa);
    data.append("hotel", hotel);
    data.append("cin", cin);
    data.append("cout", cout);
    data.append("vendor", vendor);
    data.append("id", id);

    // data.append("foo",fileData);
    // data.append("foo2",fileData2);
    // data.append("foo3",fileData3);

    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/uphotel",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          toast.success("customer Added", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          history.goBack();

          document.getElementById("create-course-form").reset();
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1000);
  }, []);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <Printhead />

        <div class="prheadc mt-3">
          <h3>Service Name: Hotel</h3>
        </div>

        <div class="dtran cview">
          <div class="d-flex adcst">
            <h5 class="m-0">Customer Data</h5>
          </div>
          <hr />

          <div class="contentC">
            <div class="form-group vcinfo">
              <div class="col-3">
                <img
                  src={
                    "https://sr-api.flydocx.com/" +
                    process.env.REACT_APP_URL +
                    "/images/" +
                    photo
                  }
                  class="clientpp2"
                  alt="..."
                />
              </div>
              <div class="col-9">
                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Serial
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cserial}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Name
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cname}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Mobile Number
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cnum}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Address
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cadd}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport No
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {passport}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport Validity
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {edate}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    VISA No
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {visa}</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="d-flex adcst mt-5">
                <h5 class="m-0">Service Data</h5>
              </div>
              <hr />

              <div class="form-group row">
                <label for="" class="col-2 col-form-label">
                  Hotel Name
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {hotel}</p>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-2 col-form-label">
                  Check-In
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {cin}</p>
                </div>
                <label for="" class="col-2 col-form-label">
                  Check-Out
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {cout}</p>
                </div>
              </div>

              <hr />

              {aid == 0 ? null : (
                <div class="form-group row">
                  <label for="" class="col-2 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-10">
                    <p class="font-weight-bold m-0 p-1">: {getagent(aid)}</p>
                  </div>
                </div>
              )}

              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label">
                  Vendor Name
                </label>
                <div class="col-4">
                  <div class="input-group">
                    {vendors.map((y) => (
                      <option class="font-weight-bold m-0 p-1">
                        {y.id == vendor ? ": " + y.vname : null}
                      </option>
                    ))}
                  </div>
                </div>
              </div>

              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label">
                  Care Of
                </label>
                <div class="col-4">
                  <div class="input-group">
                    <p class="font-weight-bold m-0 p-1">: {getStaff(cof)}</p>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Additional Info
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {note}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
          <div class="dflex" style={{ marginLeft: 10, marginRight: 10 }}>
            <p style={{ fontSize: 14, fontWeight: "bold" }}>
              Developed by mPair Technologies Ltd.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default withRouter(Hotel);
