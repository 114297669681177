import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Resizer from "react-image-file-resizer";
import Loading from "../../screens/common/loading";

var allitem = [];

function AddManpower(props) {
  var cid = props.match.params.cid;

  let history = useHistory();

  const [name, setName] = useState("");
  const [bm, setBm] = useState("");
  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [active, setActive] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");

  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [pval, setPval] = useState("");
  const [cphoto, setCphoto] = useState("");
  const [cserial, setCserial] = useState("");
  const [dob, setDob] = useState("");

  const [aid, setAid] = useState(0);
  const [agents, setAgents] = useState([]);
  const [note, setNote] = useState("");
  const [vendors, setVendors] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [load, setLoad] = useState(false);

  //MANPOWER

  const [ctype, setCtype] = useState("");
  const [visa, setVisa] = useState("");
  const [mcountry, setMcountry] = useState("");
  const [mddate, setMddate] = useState("");
  const [cof, setCof] = useState("");

  const [show, setShow] = useState(false);

  const [vendor, setVendor] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    setLoad(true);

    const data = new FormData();
    data.append("cid", cid);
    data.append("cname", cname);
    data.append("cnum", cnum);
    data.append("cadd", cadd);

    data.append("dob", dob);

    data.append("cserial", cserial);

    data.append("passport", passport);

    data.append("nid", nid);
    data.append("pval", pval);
    data.append("user", cphoto);

    data.append("visa", visa);

    data.append("country", mcountry);
    data.append("bm", bm);
    data.append("ddate", mddate);
    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("note", note);
    data.append("vendor", vendor);
    data.append("aid", aid);
    data.append("ctype", ctype);
    data.append("cof", cof);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/adman",
        data
      )
      .then((res) => {
        setLoad(false);
        if (res.data.message == null) {
          toast.success("customer Added", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          setCname("");
          setCadd("");
          setCname("");
          document.getElementById("create-course-form").reset();
          history.push("/");
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchalltypevendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => setStaffs(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomerbyid",
        {
          cid: cid,
        }
      )
      .then((res) => {
        setCname(res.data.suser[0].cname);
        setCadd(res.data.suser[0].cadd);
        setCnum(res.data.suser[0].cnum);
        setDob(res.data.suser[0].dob);
        setNid(res.data.suser[0].nid);
        setPassport(res.data.suser[0].passport);
        setPval(res.data.suser[0].pval);
        setCserial(res.data.suser[0].serial);
        setCphoto(res.data.suser[0].cphoto);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <ToastContainer />

      <div class="container border mb-4 bg-light pt-3">
        <div class="pb-3">
          <Loading load={load} />

          <form onSubmit={handleSubmit} id="create-course-form">
            <div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Customer Type
                </label>

                <div class="col-lg-4 col-12 mt-2">
                  <div class="form-check form-check-inline">
                    <input
                      name="ctype"
                      id="fit"
                      type="radio"
                      class="form-check-input"
                      value="regular"
                      required="required"
                      checked={ctype == "regular" ? true : false}
                      onChange={(e) => {
                        setCtype(e.target.value);
                        setShow(false);
                      }}
                    />
                    <label for="_0" class="form-check-label infotext mr-2">
                      Regular
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      name="ctype"
                      id="fit"
                      type="radio"
                      class="form-check-input"
                      value="agent"
                      required="required"
                      checked={ctype == "agent" ? true : false}
                      onChange={(e) => {
                        setCtype(e.target.value);
                        setShow(true);
                      }}
                    />
                    <label for="_0" class="form-check-label infotext mr-2">
                      From Agent
                    </label>
                  </div>
                </div>

                <label for="" class="col-lg-2 col-4 col-form-label infotext">
                  Care of
                </label>
                <div class="col-lg-4 col-8 me-auto">
                  <select
                    class="form-control"
                    value={cof}
                    onChange={(e) => setCof(e.target.value)}
                  >
                    <option>Choose Staff</option>
                    {staffs.map((item) => (
                      <option value={item.id}>{item.sname}</option>
                    ))}
                  </select>
                </div>
              </div>

              {ctype == "agent" ? (
                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      {ctype == "agent" ? (
                        <select
                          class="form-control"
                          value={aid}
                          onChange={(e) => setAid(e.target.value)}
                        >
                          <option>Choose Agent</option>
                          {agents.map((item) => (
                            <option value={item.id}>{item.aname}</option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Visa No
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={visa}
                    onChange={(e) => setVisa(e.target.value)}
                  />
                </div>
                <label
                  for=""
                  class="col-lg-2 col-12 marginTop col-form-label infotext"
                >
                  Country
                </label>
                <div class="col-lg-4 col-12">
                  <select
                    class="form-select form-control"
                    value={mcountry}
                    onChange={(e) => setMcountry(e.target.value)}
                  >
                    <option class="form-control" selected>
                      Choose Country
                    </option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">
                      Bosnia & Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">
                      British Indian Ocean Ter
                    </option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">
                      French Southern Ter
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">
                      Netherland Antilles
                    </option>
                    <option value="Netherlands">
                      Netherlands (Holland, Europe)
                    </option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">
                      Republic of Montenegro
                    </option>
                    <option value="Republic of Serbia">
                      Republic of Serbia
                    </option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">
                      St Pierre & Miquelon
                    </option>
                    <option value="St Vincent & Grenadines">
                      St Vincent & Grenadines
                    </option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">
                      Sao Tome & Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">
                      United Arab Emirates
                    </option>
                    <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">
                      Vatican City State
                    </option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">
                      Virgin Islands (Brit)
                    </option>
                    <option value="Virgin Islands (USA)">
                      Virgin Islands (USA)
                    </option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">
                      Wallis & Futana Is
                    </option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>
              <div class="form-group row py-1 align-items-center">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  BMET Number
                </label>
                <div class="col-lg-4 col-12 me-auto">
                  <input
                    type="text"
                    class="form-control"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    value={bm}
                    onChange={(e) => setBm(e.target.value)}
                  />
                </div>
                <label
                  for=""
                  class="col-lg-2 col-12 marginTop col-form-label infotext"
                >
                  Delivery Date
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="date"
                    class="form-control"
                    required=""
                    value={mddate}
                    onChange={(e) => setMddate(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Additional Note
                </label>
                <div class="col-lg-10 col-12">
                  <textarea
                    id="textarea"
                    name="textarea"
                    cols="40"
                    rows="2"
                    class="form-control"
                    required=""
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <hr />
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-4 col-form-label infotext">
                  Select Vendor
                </label>
                <div class="col-lg-4 col-8 me-auto">
                  <select
                    id="inputState"
                    class="form-select form-control"
                    value={vendor}
                    onChange={(e) => setVendor(e.target.value)}
                  >
                    <option class="form-control" selected>
                      Choose Vendor
                    </option>

                    {vendors.map((item) => {
                      if (item.type == "manpower") {
                        return <option value={item.id}>{item.vname}</option>;
                      }

                      if (item.type == "all") {
                        return <option value={item.id}>{item.vname}</option>;
                      }
                    })}
                  </select>
                </div>
                <label for="" class="col-lg-2 col-4 col-form-label infotext">
                  Buying Price
                </label>
                <div class="col-lg-4 col-8 me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={base}
                    onChange={(e) => setBase(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for=""
                  class="col-lg-2 col-4 marginTop col-form-label infotext"
                >
                  {ctype == "agent" ? "Agent Fare" : "Selling Price"}
                </label>
                <div class="col-lg-2 col-8 marginTop me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={gross}
                    onChange={(e) => setGross(e.target.value)}
                  />
                </div>

                <label for="" class="col-lg-2 col-4 col-form-label infotext">
                  VAT & AIT
                </label>
                <div class="col-lg-2 col-8 me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    placeholder="Ex. 0.03"
                    value={vat}
                    onChange={(e) => {
                      var p =
                        Number(gross) - (Number(base) + Number(e.target.value));
                      setVat(e.target.value);
                      setProfit(p);
                    }}
                  />
                </div>
                <label
                  for=""
                  class="col-lg-2 col-4 marginTop col-form-label infotext"
                >
                  Profit
                </label>
                <div class="col-lg-2 col-8 marginTop me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    readOnly
                    value={profit}
                    onChange={(e) => setProfit(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="form-group row py-2">
              <div class="col-lg-2"></div>
              <div class="col-lg-10 col-12">
                <button class="w-100 btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(AddManpower);
