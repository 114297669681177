import Navigation2 from "../navigation2";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Footer from "../../screens/footer";

function Addbank() {
  let history = useHistory();
  const [bname, setBname] = useState("");
  const [branch, setBranch] = useState("");
  const [name, setName] = useState("");
  const [ano, setAno] = useState("");
  const [balance, setBalance] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData();
    data.append("bname", bname);
    data.append("branch", branch);
    data.append("name", name);
    data.append("ano", ano);
    data.append("balance", balance);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/addbank",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          toast.success("Bank Added", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });
        }
      })
      .catch((err) => console.log(err));

    history.goBack();
  }

  return (
    <>
      <ToastContainer />
      <Navigation2 />
      <br></br>
      <div class="container">
        <div class="addBank">
          <h2>ADD Bank</h2>
          <hr />
          <form onSubmit={handleSubmit}>
            <div class="form-group row py-2">
              <label for="" class="col-lg-2 col-4 col-form-label infotext">
                Bank Name
              </label>
              <div class="col-lg-10 col-8">
                <input
                  onChange={(e) => setBname(e.target.value)}
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                />
              </div>
            </div>
            <div class="form-group row py-2">
              <label for="" class="col-lg-2 col-4 col-form-label infotext">
                Branch Name
              </label>
              <div class="col-lg-10 col-8">
                <input
                  onChange={(e) => setBranch(e.target.value)}
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                />
              </div>
            </div>
            <div class="form-group row py-2">
              <label for="" class="col-lg-2 col-4 col-form-label infotext">
                Account Name
              </label>
              <div class="col-lg-10 col-8">
                <input
                  onChange={(e) => setName(e.target.value)}
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                />
              </div>
            </div>
            <div class="form-group row py-2">
              <label for="" class="col-lg-2 col-4 col-form-label infotext">
                Account No
              </label>
              <div class="col-lg-4 col-8 marginB">
                <input
                  onChange={(e) => setAno(e.target.value)}
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                />
              </div>
              <label for="" class="col-lg-2 col-4 col-form-label infotext">
                Current Balance
              </label>
              <div class="col-lg-4 col-8">
                <input
                  onChange={(e) => setBalance(e.target.value)}
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  required="required"
                />
              </div>
            </div>
            <div class="form-group row py-2 justify-content-end">
              <div class="col-lg-10 col-12">
                <div class="d-grid gap-2 text-center">
                  <button class="btn btn-primary w-100 submitbtn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Addbank;
