import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Duelist from "./duelist";
import Clist from "./clist";
import Phistory from "./phistory";
import Transaction from "./transaction";
import Vat from "./vat";
import Stran from "./stran";
import Atran from "./atran";

function Report() {
  return (
    <>
      <Route path="/duelist">
        <Duelist />
      </Route>
      <Route path="/clist">
        <Clist />
      </Route>
      <Route path="/phistory">
        <Phistory />
      </Route>
      <Route path="/transaction">
        <Transaction />
      </Route>
      <Route path="/vat">
        <Vat />
      </Route>
      <Route path="/stran/:id">
        <Stran />
      </Route>
      <Route path="/atran/:id">
        <Atran />
      </Route>
    </>
  );
}
export default Report;
