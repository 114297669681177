import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import client from "../../images/placeholder.svg";
import searchb from "../../images/search.png";
import refund from "../../images/refund.png";
import trash from "../../images/trash.png";
import file from "../../images/files.png";
import download from "../../images/download.png";
import reissue from "../../images/reissue.png";
import waiting from "../../images/flightWaiting.png";
import Land from "../../images/flightLand.png";
import done from "../../images/done.png";
import axios from "axios";
import Sidebar from "../Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../screens/footer";
import Paymentmodal from "../modals/paymentmodal";
import formatDate from "../functions/timeformat";
import Delmodal from "../modals/delmodal";
import Ginvoice from "../common/invoice";

function Visa({ alldata, type }) {
  const [search, setSearch] = useState("");
  const [csearch, setCsearch] = useState("regular");

  const [photo, setPhoto] = useState("");
  const [banks, setBanks] = useState([]);
  const [stype, setStype] = useState("");
  const [refundb, setRefundb] = useState(0);
  const [re, setRe] = useState(0);
  const [abank, setAbank] = useState("");

  const [uname, setUname] = useState("");
  const [upay, setUpay] = useState("");
  const [paid, setPaid] = useState("");
  const [uid, setUid] = useState(0);
  const [view, setView] = useState("cash");

  const [vname, setVname] = useState("");
  const [serial, setSerial] = useState("");
  const [base, setBase] = useState("");

  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [tamount, setTamount] = useState(0);
  const [phistory, setPhistory] = useState([]);
  const [acid, setAcid] = useState("");
  const [acit, setAcit] = useState("");

  const [allven, setAllven] = useState([]);
  const [agents, setAgents] = useState([]);

  const [note, setNote] = useState("");

  const [snote, setSnote] = useState("");
  const [samount, setSamount] = useState("");

  const [agent, setAgent] = useState("");

  const [gross, setGross] = useState("");

  const [role, setRole] = useState(false);

  const [status, setStatus] = useState("");

  const [paymodal, setPaymondal] = useState(false);
  const [invoiceId, setInvoiceid] = useState("");
  const [invoiceservice, setInvoiceservice] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].role);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => setAllven(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function upstatus2(x) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upstatus2",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delticket() {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delticket",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function deldomticket() {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/deldomticket",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delhotticket() {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delhotticket",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delpass() {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/delpass",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delman() {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/delman",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function upstatus(x) {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/upstatus",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function refundhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    document.getElementById("spfrm2").reset();

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("refundb", re);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("name", uname);
    data.append("vname", vname);
    data.append("serial", serial);
    data.append("base", base);
    data.append("gross", gross);
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundhistory",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    toast.success("Refund Addedd", {
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      toastId: "success4",
      autoClose: 2000,
    });

    setPaid(tamount);
    setRefundb(re);
    refundfetch(uid, stype);

    setHbank("");
    setAmount("");
    setTamount(0);
  }

  function refundfetch(x, y) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        setSnote(res.data.message[0].note);
        setSamount(res.data.message[0].amount);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  return (
    <>
      <ToastContainer />
      <Ginvoice id={invoiceId} service={invoiceservice} />

      <div className="noprint">
        <Paymentmodal
          active={paymodal}
          uname={uname}
          upay={upay}
          paid={paid}
          setPaid={setPaid}
          uid={uid}
          stype={stype}
          serial={serial}
          status={status}
          close={setPaymondal}
        />

        <div class="row search-mb">
          <div class="col-lg-12 col-12 border px-0">
            <div class="border-bottom bg-light mx-0">
              <h4 class="p-2 mb-0">Visa</h4>
            </div>

            <div class="row mx-auto bg-light p-2 border-bottom">
              <div class="d-flex form-group has-search col-lg-9 col-9 m-0 p-0">
                <img src={searchb} class="sChooseIcon2" alt="..." />
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control searchbox"
                  required="required"
                  placeholder="Search Customer"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div class="col-lg-3 col-3 pr-0">
                <div class="col-12">
                  <input
                    type="date"
                    class="filtBTN-3 m-0 ml-3"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="row mx-auto">
              <ul
                class="nav nav-pills p-0 text-center col-lg-12"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item col-lg-6 col-6 px-0">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    onClick={() => setCsearch("regular")}
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Regular
                  </a>
                </li>
                <li class="nav-item col-lg-6 col-6 px-0">
                  <a
                    class="nav-link"
                    id="pills-profile-tab"
                    onClick={() => setCsearch("agent")}
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    Agent
                  </a>
                </li>
              </ul>
              <hr />
            </div>

            {type == "visa" ? (
              <>
                {alldata
                  .filter((item) => {
                    if (
                      item.cname.toLowerCase().includes(search.toLowerCase()) ||
                      item.ctype.toLowerCase().includes(search.toLowerCase()) ||
                      item.cnum.toLowerCase().includes(search.toLowerCase()) ||
                      item.cserial
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.date.toLowerCase().includes(search.toLowerCase()) ||
                      item.passport
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.country.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <>
                      {item.ctype == csearch ? (
                        <div class="card border-0 rounded-0">
                          <div class="card-body p-0 m-0">
                            <div class="row m-0 p-0 border-bottom border-top">
                              <div class="col-lg-2 col-4 p-2 my-auto">
                                {item.cphoto == "" ? (
                                  <img src={client} />
                                ) : (
                                  <img
                                    src={
                                      "https://sr-api.flydocx.com/" +
                                      process.env.REACT_APP_URL +
                                      "/images/" +
                                      item.cphoto
                                    }
                                    class="clientpp"
                                    alt="..."
                                  />
                                )}
                              </div>
                              <div class="col-lg-3 col-8 p-1 my-auto">
                                <div class="col">
                                  <p class="m-0">
                                    <span class="sldesign py-0">
                                      <small>#{item.cserial}</small>
                                    </span>
                                  </p>
                                  <p class="font-weight-bold c_name m-0">
                                    {item.cname}
                                  </p>
                                  <p class="m-0 font-weight-bold cusData">
                                    {item.cnum}
                                  </p>
                                  <p class="m-0 cusData" row="2">
                                    {item.cadd}
                                  </p>
                                  <p class="m-0 cusData">
                                    Passport:{" "}
                                    <span class="font-weight-bold">
                                      {item.passport}
                                    </span>
                                  </p>

                                  <Link
                                    class="dprofile"
                                    to={"/visa/" + item.id}
                                  >
                                    Download Data
                                  </Link>
                                </div>
                              </div>
                              <div class="col-lg-4 col-12 dflight_data p-2 pl-3">
                                <p class="m-0 cusData">
                                  Visa Status:{" "}
                                  <span class="font-weight-bold">
                                    {item.status}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Visa Number:{" "}
                                  <span class="font-weight-bold">
                                    {item.visa}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Country:{" "}
                                  <span class="font-weight-bold">
                                    {item.country}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Visa Type:{" "}
                                  <span class="font-weight-bold">
                                    {item.type}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Issue Date :{" "}
                                  <span class="font-weight-bold">
                                    {item.vstamp}
                                  </span>
                                </p>
                              </div>

                              <div class="col-lg-3 col-9 my-auto">
                                {item.ctype == "agent" ? (
                                  <div class="anbtn w-100 my-1">
                                    {getagent(item.aid)}
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setUname(item.cname);
                                      setUpay(item.gross);
                                      setPaid(item.paid);
                                      setUid(item.id);
                                      setStype("visa");
                                      setPaymondal(true);
                                    }}
                                    class="btn statusbttn w-100 my-1"
                                    type="button"
                                  >
                                    Payment Status
                                  </div>
                                )}
                                <Link
                                  to={"/updatev/" + item.id}
                                  class="btn upbttn w-100 my-1"
                                >
                                  Update Info
                                </Link>

                                <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                  <a
                                    onClick={() => {
                                      setInvoiceid(item.id);
                                      setInvoiceservice("visa");
                                      setTimeout(() => {
                                        window.print();
                                      }, 100);
                                    }}
                                    class=" sStatus text-center"
                                  >
                                    <img
                                      src={download}
                                      class="staticon"
                                      alt="..."
                                    />
                                  </a>
                                  {item.status == "PENDING" ? (
                                    <div class="sStatusOK text-center my-auto">
                                      <img
                                        onClick={(e) => upstatus(item.id)}
                                        src={done}
                                        class="staticon-2"
                                        style={{ cursor: "pointer" }}
                                        alt="Flight Done"
                                      />
                                    </div>
                                  ) : (
                                    <div class="sStatusOK text-center my-auto text-white">
                                      <p class="mb-0" style={{ paddingTop: 5 }}>
                                        Paid
                                      </p>
                                    </div>
                                  )}
                                  {role == "admin" ? (
                                    <div
                                      class=" sStatus-R2 text-center"
                                      data-toggle="modal"
                                      onClick={() => {
                                        setAcid(item.id);
                                        setAcit("visa");
                                      }}
                                      data-target="#delmodel"
                                    >
                                      <img
                                        src={trash}
                                        class="staticon"
                                        alt="..."
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
              </>
            ) : null}
          </div>
        </div>

        <Delmodal acid={acid} acit={acit} />
        <Footer />
      </div>
    </>
  );
}

export default withRouter(Visa);
