import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "./navigation2";
import searchb from "../images/search.png";
import filter from "../images/filter.png";
import axios from "axios";
import Sidebar from "./Sidebar";

function Allcustomer(props) {
  var type = props.match.params.type;
  const [alldata, setAlldata] = useState([]);
  const [tdata, setTdata] = useState([]);
  const [search, setSearch] = useState("");
  const [term, setTerm] = useState("");
  const [sdata, setSdata] = useState([]);
  const [total, setTotal] = useState("");
  const [paid, setPaid] = useState(0);

  const [uid, setUid] = useState(0);
  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [tamount, setTamount] = useState(0);
  const [phistory, setPhistory] = useState([]);
  const [stype, setStype] = useState("vendor");
  const [banks, setBanks] = useState([]);
  const [abank, setAbank] = useState("");
  const [b1, setB1] = useState("");
  const [b2, setB2] = useState("");
  const [b3, setB3] = useState("");
  const [deal, setDeal] = useState(0);
  const [name, setName] = useState("");
  const [visa, setVisa] = useState([]);
  const [hotel, setHotel] = useState([]);

  const [ticket, setTicket] = useState([]);

  const [man, setMan] = useState([]);
  const [mofa, setMofa] = useState([]);
  const [umra, setUmrah] = useState([]);
  const [hajj, setHajj] = useState([]);
  const [medical, setMedical] = useState([]);
  const [passport, setPassport] = useState([]);

  const [acid, setAcid] = useState("");
  const [acit, setAcit] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => {
        setAlldata(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [alldata]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, [banks]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchticket"
      )
      .then((res) => setTicket(res.data.message))
      .catch((err) => console.log(err));
  }, [banks]);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchhotel"
      )
      .then((res) => setHotel(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchpassport"
      )
      .then((res) => setPassport(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/fetchvisa"
      )
      .then((res) => setVisa(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/fetchman"
      )
      .then((res) => setMan(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/umrahfetch"
      )
      .then((res) => {
        setUmrah(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/hajjfetch"
      )
      .then((res) => setHajj(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/medicalfetch"
      )
      .then((res) => setMedical(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function fetchall(x) {
    axios
      .post(
        "https://sr-api.flydocx.com/" + process.env.REACT_APP_URL + "/fetchall",
        {
          term: x,
        }
      )
      .then((res) => {
        setTotal(res.data.message[0].total);
      })
      .catch((err) => console.log(err));
  }

  function payhistory() {
    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("abank", abank);
    data.append("name", name);

    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/payhistory",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    setPaid(tamount);
    setPtype("cash");
    historyfetch(uid, stype);
  }

  function historyfetch(x, y) {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => setPhistory(res.data.message))
      .catch((err) => console.log(err));
  }

  function delcustomer() {
    axios
      .post(
        "https://sr-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delcustomer",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <>
      <Navigation2 />

      <div
        class="modal fade deleteModal animate__animated animate__slideInDown"
        id="delmodel"
        tabindex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ top: 20 }} role="document">
          <div class="modal-content datadelM">
            <div class="modal-body">
              <p class="m-0 py-3">Do You Want to Delete The Customer?</p>
            </div>
            <div class="modal-footer py-2">
              <button
                type="button"
                class="btn btn-secondary btndlet"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary btnyes"
                onClick={() => {
                  delcustomer();
                }}
                data-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-9 col-12 my-4">
            <div class="search-mb mb-3">
              <h3>Customer Data</h3>
              <hr />
            </div>

            <div class="row search-mb mb-3">
              <div class="form-group has-search col-12 m-0 p-0">
                <img src={searchb} class="sChooseIcon2" alt="..." />
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control searchbox"
                  required="required"
                  placeholder="Search Customer"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>

            {alldata
              .filter((item) => {
                if (
                  item.cname.toLowerCase().includes(search.toLowerCase()) ||
                  item.cnum.toLowerCase().includes(search.toLowerCase()) ||
                  item.id
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.passport
                    .toString()
                    .toLowerCase()
                    .includes(search.toLowerCase())
                ) {
                  return item;
                }
              })
              .map((item) => (
                <div class="card search-mb my-1">
                  <div class="card-body p-0 m-0">
                    <div class="row m-0 p-0">
                      <div class="col-lg-3 col-12 pl-3 p-2">
                        <p class="m-0">FD#{item.id}</p>
                        <p class="font-weight-bold c_name m-0">{item.cname}</p>
                        <p class="m-0 font-weight-bold cusData">{item.cnum}</p>
                        <p class="m-0 cusData" row="2">
                          {item.vadd}
                        </p>
                      </div>

                      <div class="col-lg-4 col-12 dflight_data pl-3 p-2">
                        <p class="m-0">
                          Address: <span>{item.cadd}</span>
                        </p>
                        <p class="m-0">
                          Passport: <span>{item.passport}</span>
                        </p>
                        <p class="m-0">
                          NID: <span>{item.nid}</span>
                        </p>
                      </div>

                      <div class="col-lg-4 col-12 p-2 pl-3">
                        {ticket.map((y) => (
                          <>
                            {y.cnum == item.cnum && y.cname == item.cname ? (
                              <p>
                                <b>
                                  {y.type == "international"
                                    ? "International"
                                    : "Domestic"}
                                </b>{" "}
                                : {formatDate(y.date)}
                              </p>
                            ) : null}
                          </>
                        ))}

                        {hotel.map((y) => (
                          <>
                            {y.cnum == item.cnum && y.cname == item.cname ? (
                              <p>
                                <b>Hotel Booking</b> : {formatDate(y.date)}
                              </p>
                            ) : null}
                          </>
                        ))}

                        {visa.map((y) => (
                          <>
                            {y.cnum == item.cnum && y.cname == item.cname ? (
                              <p>
                                <b>Visa</b> : {formatDate(y.date)}
                              </p>
                            ) : null}
                          </>
                        ))}

                        {man.map((y) => (
                          <>
                            {y.cnum == item.cnum && y.cname == item.cname ? (
                              <p>
                                <b>Manpower</b> : {formatDate(y.date)}
                              </p>
                            ) : null}
                          </>
                        ))}

                        {umra.map((y) => (
                          <>
                            {y.cnum == item.cnum && y.cname == item.cname ? (
                              <p>
                                <b>Umrah</b> : {formatDate(y.date)}
                              </p>
                            ) : null}
                          </>
                        ))}

                        {hajj.map((y) => (
                          <>
                            {y.cnum == item.cnum && y.cname == item.cname ? (
                              <p>
                                <b>Hajj</b> : {formatDate(y.date)}
                              </p>
                            ) : null}
                          </>
                        ))}

                        {medical.map((y) => (
                          <>
                            {y.cnum == item.cnum && y.cname == item.cname ? (
                              <p>
                                <b>Medical</b> : {formatDate(y.date)}
                              </p>
                            ) : null}
                          </>
                        ))}

                        {passport.map((y) => (
                          <>
                            {y.cnum == item.cnum && y.cname == item.cname ? (
                              <p>
                                <b>Passport</b> : {formatDate(y.date)}
                              </p>
                            ) : null}
                          </>
                        ))}
                      </div>

                      <div class="col-lg-1 col-12 my-auto p-2 pl-3">
                        <button
                          className="btn btn-primary m-1"
                          data-toggle="modal"
                          onClick={() => {
                            setAcid(item.id);
                          }}
                          data-target="#delmodel"
                        >
                          <i class="fa fa-trash"></i>
                        </button>

                        <Link
                          className="btn btn-primary m-1"
                          to={"/editcustomer/" + item.id}
                        >
                          <i class="fa fa-edit"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <Sidebar />
        </div>
      </div>
    </>
  );
}

export default withRouter(Allcustomer);
